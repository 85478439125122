import React from 'react';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import constants from '../../../utils/constants';
import { getProductMarketPlaces } from '../../../utils/methods';

const StatusActionColumn = ({ product, clientId }) => {
  const marketplaces = getProductMarketPlaces(product);
  const listed = marketplaces.filter((o) => o.listed);
  const delisted = listed.filter((o) => o.value && o.value.url == 'd');
  const isDefault =
    product?.prodStatus === constants.PRODUCT_STATUSES.INVENTORY &&
    !listed.length;

  return product?.status ? (
    listed.length || isDefault ? (
      <>
        <Link
          to={`/product/${clientId}/${product._id}`}
          className="btn btn-success btn-sm"
        >
          {' '}
          Product listed
        </Link>
      </>
    ) : delisted.length ? (
      <Link
        to={`/product/${clientId}/${product._id}`}
        className="btn btn-secondary btn-sm"
      >
        {' '}
        Product delisted
      </Link>
    ) : (
      <Link
        to={`/product/${clientId}/${product._id}`}
        className="btn btn-warning btn-sm"
      >
        List Product
      </Link>
    )
  ) : (
    <Link
      to={`/product/${clientId}/${product?._id}`}
      className="btn btn-danger btn-sm"
    >
      {' '}
      Deleted by user
    </Link>
  );
};

StatusActionColumn.defaultProps = {
  product: {},
  clientId: '0',
};

StatusActionColumn.propTypes = {
  product: PropTypes.object.isRequired,
  clientId: PropTypes.string.isRequired,
};

export default StatusActionColumn;
