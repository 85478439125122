import { CircularProgress, DialogContent, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import SweetAlerts from 'src/services/SweetAlerts';
import Axios from '../../../services/Axios';
import EnhancedTable from './ProductTable/MainTable';
interface ShopifyModalProps {
  showModal: boolean;
  onShowModal: (show: boolean) => void;
  clientId: string;
}
let pageArr: any[] = [];

const ImportShopifyModal = ({
  showModal,
  onShowModal,
  clientId,
}: ShopifyModalProps) => {
  // const descriptionElementRef = React.useRef<HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [productIds, setProductIds] = useState<string[]>([]);
  const [visitedPages, setVisitedPages] = useState<number[]>([0]);
  const [productsAppear, setProductsAppear] = useState(false);

  const fetchData = async (info: any, pageLimit: number) => {
    setLoading(true);
    try {
      const params = qs.stringify({ limit: pageLimit, pageInfo: info });
      await Axios.get(`/shopify/product/${clientId}?${params}`).then(
        async (res) => {
          if (res.data.products === 0) {
            setProductsAppear(true);
          } else {
            setProducts([...products, ...res.data.products]);
            pageArr.push(res.data.pagination.pageInfo);
            setTotalCount(res.data.pagination.totalCount);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage > page) {
      if (!visitedPages.includes(newPage)) {
        setVisitedPages([...visitedPages, newPage]);
        fetchData(pageArr[page], rowsPerPage);
      }
    }

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newLimit = parseInt(event.target.value, 10);
    if (newLimit !== rowsPerPage) {
      pageArr = [];
      setProducts([]);
      setPage(0);
      setRowsPerPage(newLimit);
      fetchData(null, newLimit);
    }
  };

  const handleClose = () => {
    onShowModal(false);
  };

  const handleAddProductIds = (prodId: string[]) => {
    setProductIds(prodId);
  };

  const handleImport = () => {
    Axios.post(`/shopify/product/import/${clientId}`, {
      shopifyIds: productIds,
    })
      .then((res) => {
        if (res.status === 200) {
          onShowModal(false);
          SweetAlerts.SuccessToaster.fire({
            title: 'Products Imported Successfully',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (showModal) {
      fetchData(null, rowsPerPage);
    }
  }, [showModal]);

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={showModal}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Import Shopify Products
        </DialogTitle>
        {!productsAppear ? (
          <DialogContent id="scroll-dialog-description" dividers={true}>
            <div
              style={{
                overflowY: 'scroll',
                width: '100%',
                float: 'left',
                height: '500px',
                position: 'relative',
              }}
            >
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '160px',
                  }}
                >
                  <CircularProgress size={100} />
                </div>
              ) : (
                <EnhancedTable
                  products={products}
                  rowsPerPage={rowsPerPage}
                  onHandleChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  onHandleChangePage={handleChangePage}
                  totalCount={totalCount}
                  onHandleAddProductIds={handleAddProductIds}
                />
              )}
            </div>
          </DialogContent>
        ) : (
          <DialogContent id="scroll-dialog-description" dividers={true}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <Typography variant="h6">
                No Products Found In Shopify Store !
              </Typography>
            </div>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {!productsAppear ? (
            <Button onClick={handleImport} color="primary">
              Import
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImportShopifyModal;
