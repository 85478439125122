import { Button } from '@material-ui/core';
import FileType from 'file-type';
import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { DebounceInput } from 'react-debounce-input';
import { BsLink45Deg } from 'react-icons/bs';
import Axios, {
  assetsThumbnailURL,
  assetsURL,
  get,
} from '../../services/Axios';
import { ErrorToaster, SuccessToaster } from '../../services/SweetAlerts';
import AddCrossListedProductModal from '../AddCrossListedProductModal';
import Pagination from '../Pagination/Pagination';
import CustomCheckBox from '../utils/CustomCheckBox';
import StatusActionColumn from './Columns/StatusActionColumn';
import StatusColumn from './Columns/StatusColumn';
import ImportShopifyModal from './importShopify/ImportShopifyModal';
import ProductChangeTrigger from './ProductChangeTrigger';
import './products.css';

const productHeaders = [
  { label: 'Handle', key: 'handle' },
  { label: 'Title', key: 'title' },
  { label: 'Body (HTML)', key: 'shortDescription' },
  { label: 'Vendor', key: 'vendor' },
  { label: 'Standardized Product Type', key: 'standardized_product_type' },
  { label: 'Hammoq Category', key: 'ebayCategoryField' },
  { label: 'Published', key: 'prodStatus' },
  { label: 'option1 Name', key: 'option1Name' },
  { label: 'option1 Value', key: 'option1Value' },
  { label: 'option2 Name', key: 'option2Name' },
  { label: 'option2 Value', key: 'option2Value' },
  { label: 'option3 Name', key: 'option3Name' },
  { label: 'option3 Value', key: 'option3Value' },
  { label: 'Variant SKU', key: 'sku' },
  { label: 'SEO Title', key: 'title' },
  { label: 'SEO Description', key: 'shortDescription' },
  { label: 'Short Description', key: 'shortDescription' },
  { label: 'Variant Inventory Qty', key: 'quantity' },
  { label: 'Variant Grams', key: 'weightOZ' },
  { label: 'Variant Price', key: 'price' },
  { label: 'Variant Compare At Price', key: 'compPriceIncreaseValue' },
  { label: 'Cost per item', key: 'costOfGoods' },
  { label: 'Status', key: 'status' },
  { label: 'Image Src', key: 'default_Image' },
];

const shopifyCategories = [
  'Apparel & Accessories > Shoes',
  'Media > Music & Sound Recordings > Music CDs',
  'Media > Books > Audiobooks',
  'Sporting Goods > Outdoor Recreation > Cycling > Bicycles',
];
const allowedStatusToUseBulk = ['draft', 'inventory', 'approved_drafts'];
export default class ViewProduct extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      rowPerPage: 10,
      totalPage: 1,
      products: [],
      productMessageSeen: [],
      labels: [],
      search: '',
      searchedProducts: [],
      client: {},
      datesort: false,
      filterstate: 0,
      productf: [],
      filteredProducts: [],
      submittedCount: 0,
      draftCount: 0,
      approvedDraftCount: 0,
      inventoryCount: 0,
      productStatus: 'submitted',
      showCrosslistedProducts: false,
      //flag to differentiate b/w the submitted-draft-inventory from  crossliated product part products.
      crossListingModalOpen: false,
      crosslistedproducts: [],
      isCrossListedProductsFetched: false,
      crossListedCount: 0,
      selectedProducts: [],
      productData: [],
      loadingClient: true,
      showImportModal: false,
      hasShopify: true,
    };
    this.csvLinkEl = React.createRef();
  }

  handleImportModalOpen = (show) => {
    this.setState({
      showImportModal: show,
    });
  };

  handleCrossListingModalOpen = (value) => {
    this.setState({ crossListingModalOpen: value });
  };
  downloadProduct = async () => {
    this.setState({ loading: true });
    try {
      const pdata = await this.getAllProducts();
      let finalData = [];
      this.setState({ loading: false });
      let i = 0;

      Object.entries(pdata).forEach(([key, value]) => {
        Object.entries(value).forEach(([subkey, subvalue]) => {
          if ('brand_image' in subvalue) {
            Object.entries(subvalue).forEach(([imagekey, imagevalue]) => {
              finalData[i] = imagevalue[0];
              i++;
            });
          } else {
            finalData[i] = subvalue;
            i++;
          }
        });
      });
      this.setState({ productData: finalData }, () => {
        setTimeout(() => {
          this.csvLinkEl.current.link.click();
        });
      });
    } catch (error) {
      this.setState({ loading: false });
      const alertString = error?.response?.data?.err
        ? error.response.data.err
        : 'There is issue while exporting product';

      return ErrorToaster.fire({
        title: alertString,
        timer: 3000,
      });
    }
  };

  getAllProducts = async () => {
    const { clientid } = this.props.match.params;
    const res = await Axios.get(
      `/product/${clientid}/type/all?getFieldsForShopify=true`
    );
    const prodArr = res.data.data.map((product) => {
      let handle = product.title
        ? product.title.replace(' ', '_').toLowerCase()
        : '';
      let extraDescriptions = [],
        ebayCategoryField = [],
        prodNewArr = {};
      let option1Name = '',
        option1Value = '',
        option2Name = '',
        option2Value = '',
        option3Name = '',
        option3Value = '',
        categoryName = '',
        defaultImage = '',
        standardized_product_type = '';

      if (
        typeof product.shortDescription !== 'undefined' &&
        product.shortDescription
      ) {
        product.shortDescription = decodeURIComponent(product.shortDescription);
      } else {
        product.shortDescription = '';
      }

      if (
        typeof product.images !== 'undefined' &&
        product.images.default_image &&
        typeof product.images.default_image !== 'undefined' &&
        product.images.default_image != null
      ) {
        defaultImage = assetsURL + product.images.default_image;
      }

      if (typeof product.images !== 'undefined') {
        Object.entries(product.images).forEach(([key, value]) => {
          if (key != 'default_image') {
            prodNewArr[key] = [
              { handle: handle, default_Image: assetsURL + value },
            ];
          }
        });
      }

      if (product.ebayCategoryField) {
        ebayCategoryField = JSON.parse(product.ebayCategoryField);
        categoryName = ebayCategoryField['categoryName'];

        shopifyCategories.forEach((shopifyCategory) => {
          if (
            shopifyCategory.indexOf(ebayCategoryField['categoryName']) !== -1
          ) {
            standardized_product_type = shopifyCategory;
          }
        });
      }

      if (product.extraDescriptions) {
        extraDescriptions = JSON.parse(product.extraDescriptions);
        extraDescriptions.forEach((description) => {
          if (
            description.aspectRequired &&
            description.aspectUsage === 'RECOMMENDED'
          ) {
            if (option1Name == '' && option1Value == '') {
              option1Name = description.key;
              option1Value = description.value;
              return;
            } else if (option2Name == '' && option2Value == '') {
              option2Name = description.key;
              option2Value = description.value;
              return;
            } else if (option3Name == '' && option3Value == '') {
              option3Name = description.key;
              option3Value = description.value;
              return;
            }
          } else if (
            !description.aspectRequired &&
            description.aspectUsage === 'RECOMMENDED'
          ) {
            if (option1Name == '' && option1Value == '') {
              option1Name = description.key;
              option1Value = description.value;
              return;
            } else if (option2Name == '' && option2Value == '') {
              option2Name = description.key;
              option2Value = description.value;
              return;
            } else if (option3Name == '' && option3Value == '') {
              option3Name = description.key;
              option3Value = description.value;
              return;
            }
          } else if (
            !description.aspectRequired &&
            description.aspectUsage === 'OPTIONAL'
          ) {
            if (option1Name == '' && option1Value == '') {
              option1Name = description.key;
              option1Value = description.value;
              return;
            } else if (option2Name == '' && option2Value == '') {
              option2Name = description.key;
              option2Value = description.value;
              return;
            } else if (option3Name == '' && option3Value == '') {
              option3Name = description.key;
              option3Value = description.value;
              return;
            }
          }
        });
      }

      return [
        {
          ...product,
          handle: handle,
          shortDescription: product.shortDescription,
          extraDescriptions: extraDescriptions,
          option1Name: option1Name,
          option1Value: option1Value,
          option2Name: option2Name,
          option2Value: option2Value,
          option3Name: option3Name,
          option3Value: option3Value,
          ebayCategoryField: categoryName,
          default_Image: defaultImage,
          standardized_product_type: standardized_product_type,
        },
        prodNewArr,
      ];
    });
    return prodArr;
  };

  downloadImages = (images) => {
    Object.keys(images).forEach(async (image) => {
      if (images[image]) {
        const response = await fetch(`${assetsURL + images[image]}`);
        response.blob().then((blob) => {
          if (images[image].split('.').length > 1) {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = image + '-' + images[image];
            a.click();
          } else {
            const reader = new FileReader();
            reader.addEventListener('loadend', async () => {
              var type = await FileType.fromBuffer(reader.result);
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              type = type ? '.' + type.ext : type;
              a.download = image + '-' + images[image] + type;
              a.click();
            });
            reader.readAsArrayBuffer(blob);
          }
        });
      }
    });
  };

  getShopify = async () => {
    const { clientid } = this.props.match.params;
    try {
      await Axios.get(`/shopify/oAuth/${clientid}`);
      this.setState({ hasShopify: true });
    } catch (error) {
      this.setState({ hasShopify: false });
    }
  };

  componentDidMount = () => {
    this.fetchData();
    this.fetchClientDetails();
    this.getCrossListedProducts();
    this.getShopify();
  };

  handleInventory = () => {
    const Inventory = this.state.products.filter(
      (product) => product.prodStatus === 'inventory'
    );
    this.setState({ filteredProducts: Inventory, productStatus: 'inventory' });
  };

  handleDrafts = () => {
    const Drafts = this.state.products.filter(
      (product) => product.status === 'draft'
    );
    this.setState({ filteredProducts: Drafts, productStatus: 'draft' });
  };

  handleSubmitted = () => {
    const submittedProduct = this.state.products.filter(
      (product) => product.prodStatus === 'submitted'
    );
    this.setState({
      filteredProducts: submittedProduct,
      productStatus: 'submitted',
    });
  };

  fetchClientDetails = async () => {
    this.setState({ loadingClient: true });
    const { clientid } = this.props.match.params;
    const path = `/client/${clientid}`;
    const data = await get(path);
    if (data.error) {
      ErrorToaster.fire('Something went wrong.');
    } else {
      this.setState({ client: data, loadingClient: false });
    }
  };

  fetchData = () => {
    if (this.state.productStatus == 'crossListedProducts') {
      this.getCrossListedProducts();
      return;
    }
    const { clientid } = this.props.match.params;
    Axios.get(`/product/${clientid}/type/${this.state.productStatus}`, {
      params: {
        page: this.state.page,
        size: this.state.rowPerPage,
        search: this.state.search,
      },
    })
      .then(({ data }) => {
        this.setState({
          products: data.data,
          submittedCount: data.count.submittedCount,
          listingProblemsCount: data.count.conflictedDraftCount,
          draftCount: data.count.draftCount,
          approvedDraftCount: data.count.approvedDraftCount,
          inventoryCount: data.count.inventoryCount,
          totalPage: data.pages || 1,
        });
      })
      .catch((err) => console.log(err));
  };

  handleCrossListedProducts = () => {
    this.setState({
      showCrosslistedProducts: true,
      productStatus: 'crossListedProducts',
    });
    if (!this.state.isCrossListedProductsFetched) {
      this.getCrossListedProducts();
    }
  };

  getCrossListedProducts = async () => {
    try {
      const { clientid } = this.props.match.params;
      const crosslistedproductResponse = await Axios.get(
        `/product/crosslistedproduct/${clientid}`,
        {
          params: { page: this.state.page, size: this.state.rowPerPage },
        }
      );

      this.setState({
        crosslistedproducts: crosslistedproductResponse?.data?.products || [],
        isCrossListedProductsFetched: true,
        totalPage: crosslistedproductResponse?.data?.totalPages || 1,
        crossListedCount: crosslistedproductResponse?.data?.totalProducts || 0,
      });
    } catch (err) {
      ErrorToaster.fire({ text: 'Error in getting crosslisted products' });
    }
  };

  handleFilter = (value) => {
    const { clientid } = this.props.match.params;
    Axios.get(`/product/${clientid}/type/${value}`, {
      params: {
        page: this.state.page,
        size: this.state.rowPerPage,
        search: this.state.search,
      },
    })
      .then(({ data }) => {
        this.setState({
          showCrosslistedProducts: false,
          products: data.data || [],
          totalPage: data.pages,
          productStatus: value,
          selectedProducts: [],
        });
      })
      .catch((err) => console.log(err));
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({ search: value, rowsPerPage: 10, page: 1 }, async () => {
      this.fetchData();
    });
  };

  datesort = () => {
    let { products, searchedProducts, filteredProducts, datesort } = this.state;
    if (products.length > 0)
      products = products.reverse().map((product, idx) => {
        return product;
      });
    if (searchedProducts.length > 0)
      searchedProducts = searchedProducts.reverse().map((product, idx) => {
        return product;
      });
    if (filteredProducts.length > 0)
      filteredProducts = filteredProducts.reverse().map((product, idx) => {
        return product;
      });
    this.setState({ products, searchedProducts, filteredProducts });
    this.setState({ datesort: !datesort });
  };

  selectItemHandler = (e, product) => {
    let updatedSelected = [...this.state.selectedProducts];

    if (e.target.checked) updatedSelected.push(product._id.toString());
    else
      updatedSelected = updatedSelected.filter(
        (p) => p !== product._id.toString()
      );

    this.setState({ selectedProducts: updatedSelected });
  };

  isItemSelected = (product) => {
    const { selectedProducts } = this.state;

    return (
      selectedProducts.length > 0 &&
      selectedProducts.some((p) => p === product._id.toString())
    );
  };

  moveToInventoryHandler = async (fromStatus) => {
    const { selectedProducts, inventoryCount, draftCount, approvedDraftCount } =
      this.state;

    const payload = selectedProducts.map((pId) => ({
      productId: pId,
      status: 'inventory',
    }));
    try {
      await Axios.put('product/status/bulk', { statusChanges: payload });

      this.updateListsAndResetSelectedProducts(
        selectedProducts,
        inventoryCount + selectedProducts.length,
        fromStatus === 'draft'
          ? draftCount - selectedProducts.length
          : draftCount,
        fromStatus === 'approved_drafts'
          ? approvedDraftCount - selectedProducts.length
          : approvedDraftCount
      );

      SuccessToaster.fire('The products has been moved successfully');
    } catch (error) {
      ErrorToaster.fire('Something went wrong.');
    }
  };

  moveToDraftHandler = async () => {
    const { selectedProducts, draftCount, inventoryCount, approvedDraftCount } =
      this.state;

    const payload = selectedProducts.map((pId) => ({
      productId: pId,
      status: 'draft',
    }));

    try {
      await Axios.put('product/status/bulk', { statusChanges: payload });

      this.updateListsAndResetSelectedProducts(
        selectedProducts,
        inventoryCount - selectedProducts.length,
        draftCount + selectedProducts.length,
        approvedDraftCount
      );
      SuccessToaster.fire('The products has been moved successfully');
    } catch (error) {
      ErrorToaster.fire('Something went wrong.');
    }
  };

  updateListsAndResetSelectedProducts = (
    selectedProducts,
    updatedInventoryCount,
    updatedDraftCount,
    updatedApprovedDraftCount
  ) => {
    const updatedProducts = this.state.products.filter(
      (p) => !selectedProducts.some((s) => s === p._id.toString())
    );
    const updatedSearchedProducts = this.state.searchedProducts.filter(
      (p) => !selectedProducts.some((s) => s === p._id.toString())
    );
    const updatedFilteredProducts = this.state.filteredProducts.filter(
      (p) => !selectedProducts.some((s) => s === p._id.toString())
    );

    this.setState({
      selectedProducts: [],
      products: updatedProducts,
      searchedProducts: updatedSearchedProducts,
      filteredProducts: updatedFilteredProducts,
      inventoryCount: updatedInventoryCount,
      draftCount: updatedDraftCount,
      approvedDraftCount: updatedApprovedDraftCount,
    });
  };

  buildValidURL = (url) => {
    if (url.indexOf('https') === -1 && url.indexOf('http') === -1) {
      return `https://${url}`;
    }

    return url;
  };

  render() {
    const {
      products,
      search,
      searchedProducts,
      datesort,
      filteredProducts,
      client,
      inventoryCount,
      draftCount,
      approvedDraftCount,
      submittedCount,
      page,
      totalPage,
      rowPerPage,
      listingProblemsCount,
      productData,
      loadingClient,
    } = this.state;
    const { clientid } = this.props.match.params;
    const dispProducts =
      searchedProducts.length > 0
        ? searchedProducts
        : filteredProducts.length
        ? filteredProducts
        : products;

    return (
      <div>
        {loadingClient ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Loading Client Details...
          </div>
        ) : (
          <div>
            <div className="text-align-right mt-1"></div>
            <ProductChangeTrigger
              products={dispProducts}
              currentPage={page}
              totalPage={totalPage}
              rowsPerPage={rowPerPage}
            />
            <div className="row">
              <div className="col-4">
                <h4>Seller : {client.firstName}'s Products</h4>
              </div>
              <div className="col-4">
                <h4>
                  Balance : $ {client.balance ? client.balance.toFixed(2) : 0}
                </h4>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <DebounceInput
                    type="text"
                    value={search}
                    onChange={this.handleSearchChange}
                    debounceTimeout={300}
                    className="form-control"
                    placeholder="Search"
                  />
                </div>
              </div>
            </div>

            <div className="product__info d-flex justify-content-between">
              <div style={{ justifyContent: 'space-evenly' }}>
                <button
                  id="btn-inventory"
                  className={
                    this.state.productStatus == 'inventory'
                      ? 'btn btn-primary d-inline mr-1 mb-1'
                      : 'btn btn-outline-primary d-inline mr-1 mb-1'
                  }
                  onClick={() => {
                    this.handleFilter('inventory');
                  }}
                >
                  {inventoryCount} - Inventory
                </button>
                <button
                  id="btn-draft"
                  className={
                    this.state.productStatus == 'draft'
                      ? 'btn btn-primary d-inline mr-1 mb-1'
                      : 'btn btn-outline-primary d-inline mr-1 mb-1'
                  }
                  onClick={() => {
                    this.handleFilter('draft');
                  }}
                >
                  {draftCount} - Drafts
                </button>
                <button
                  id="btn-approved-draft"
                  className={
                    this.state.productStatus === 'approved_drafts'
                      ? 'btn btn-primary d-inline mr-1 mb-1'
                      : 'btn btn-outline-primary d-inline mr-1 mb-1'
                  }
                  onClick={() => {
                    this.handleFilter('approved_drafts');
                  }}
                >
                  {approvedDraftCount} - Approved Drafts
                </button>
                <button
                  id="btn-submitted"
                  className={
                    this.state.productStatus == 'submitted'
                      ? 'btn btn-primary d-inline mr-1 mb-1'
                      : 'btn btn-outline-primary d-inline mr-1 mb-1'
                  }
                  onClick={() => {
                    this.handleFilter('submitted');
                  }}
                >
                  {submittedCount} - Submitted
                </button>
                <button
                  id="btn-conflicts"
                  className={
                    this.state.productStatus == 'conflicted'
                      ? 'btn btn-danger d-inline mr-1 mb-1'
                      : 'btn btn-outline-danger d-inline mr-1 mb-1'
                  }
                  onClick={() => {
                    this.handleFilter('conflicted');
                  }}
                >
                  {listingProblemsCount} - Conflicts
                </button>
                <button
                  id="btn-export-shopify-csv"
                  className={'btn btn-primary d-inline mr-1 mb-1'}
                  onClick={this.downloadProduct}
                >
                  Export as Shopify CSV
                </button>
                <CSVLink
                  headers={productHeaders}
                  filename="Product Export.csv"
                  data={productData}
                  ref={this.csvLinkEl}
                />
                {!this.hasShopify ? (
                  <button
                    id="btn-export-shopify-csv"
                    className={'btn btn-primary d-inline mr-1 mb-1'}
                    onClick={() => this.handleImportModalOpen(true)}
                  >
                    Import Shopify Products
                  </button>
                ) : null}
                <ImportShopifyModal
                  clientId={this.props.match.params.clientid}
                  showModal={this.state.showImportModal}
                  onShowModal={this.handleImportModalOpen}
                />
              </div>
              <div>
                {this.state.showCrosslistedProducts && (
                  <button
                    className="btn btn-outline-secondary mr-2"
                    onClick={() => this.handleCrossListingModalOpen(true)}
                  >
                    Add new cross listing
                  </button>
                )}
                <button
                  id="btn-cross-listed"
                  className={
                    this.state.showCrosslistedProducts
                      ? 'btn btn-primary d-inline'
                      : 'btn btn-outline-primary d-inline'
                  }
                  onClick={() => {
                    this.handleCrossListedProducts();
                  }}
                >
                  {this.state.crossListedCount} - Cross Listed
                </button>

                {(this.state.productStatus === 'draft' ||
                  this.state.productStatus === 'approved_drafts') && (
                  <Button
                    id="btn-move-inventory"
                    variant="contained"
                    color="primary"
                    disabled={!this.state.selectedProducts.length}
                    style={{ marginLeft: 5 }}
                    onClick={() =>
                      this.moveToInventoryHandler(this.state.productStatus)
                    }
                  >
                    Move to Inventory
                  </Button>
                )}

                {this.state.productStatus === 'inventory' && (
                  <Button
                    id="btn-move-draft"
                    variant="contained"
                    color="primary"
                    disabled={!this.state.selectedProducts.length}
                    style={{ marginLeft: 5 }}
                    onClick={this.moveToDraftHandler}
                  >
                    Move to Draft
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}

        <table className="table">
          <thead>
            <tr>
              {this.state.productStatus == 'crossListedProducts' ? (
                <>
                  <th>No</th>
                  <th>Title</th>
                  <th>Crosslisted From</th>getCrossListedProducts
                  <th>Crosslisted To</th>
                </>
              ) : (
                <>
                  {' '}
                  <th>No</th>
                  {allowedStatusToUseBulk.includes(
                    this.state.productStatus
                  ) && <th>#</th>}
                  <th className="imageIt">Image</th>
                  <th>Title</th>
                  <th>Status</th>
                  <th>
                    <b>Action</b>
                  </th>
                  <th className="date">
                    <button
                      className="btn btn-sm btn-without-bot"
                      onClick={() => {
                        this.datesort();
                      }}
                    >
                      {' '}
                      <i
                        className={
                          datesort ? 'fas fa-sort-up' : 'fas fa-sort-down'
                        }
                        aria-hidden="true"
                      ></i>
                      <b>DATE</b>
                    </button>
                  </th>
                  <th>Notes</th>
                </>
              )}
            </tr>
          </thead>
          {!this.state.showCrosslistedProducts ? (
            <tbody>
              {dispProducts.map((product, idx) => {
                const thumbnailPath =
                  typeof product.images !== 'undefined' &&
                  product.images.default_image
                    ? product.images.default_image.substring(
                        0,
                        product.images.default_image.indexOf(':')
                      ) !== 'http' &&
                      product.images.default_image.substring(
                        0,
                        product.images.default_image.indexOf(':')
                      ) !== 'https'
                      ? assetsThumbnailURL + product.images.default_image
                      : product.images.default_image
                    : '';
                const fullSizePath =
                  typeof product.images !== 'undefined' &&
                  product.images.default_image
                    ? product.images.default_image.substring(
                        0,
                        product.images.default_image.indexOf(':')
                      ) !== 'http' &&
                      product.images.default_image.substring(
                        0,
                        product.images.default_image.indexOf(':')
                      ) !== 'https'
                      ? assetsURL + product.images.default_image
                      : product.images.default_image
                    : '';
                return (
                  <tr key={`row with idx${idx}`}>
                    <td>
                      {this.state.rowPerPage * (this.state.page - 1) + idx + 1}
                    </td>

                    {allowedStatusToUseBulk.includes(
                      this.state.productStatusshopify
                    ) && (
                      <td className="product-checkbox">
                        <CustomCheckBox
                          onChange={(e) => this.selectItemHandler(e, product)}
                          checked={this.isItemSelected(product)}
                        />
                      </td>
                    )}

                    <td>
                      <img
                        src={thumbnailPath}
                        className="product-img"
                        onError={(e) => {
                          if (e.target.src !== fullSizePath) {
                            e.target.src = fullSizePath;
                          }
                          e.target.onerror = null;
                        }}
                        alt="Default pic Missing"
                      />
                      <button
                        className="btn btn-small btn-primary mt-2"
                        onClick={() => this.downloadImages(product.images)}
                      >
                        Download Images
                      </button>
                    </td>
                    <td className="product-title">{product.title}</td>
                    <td>
                      <StatusColumn product={product} />
                    </td>

                    <td>
                      <StatusActionColumn
                        clientId={clientid}
                        product={product}
                      />
                    </td>
                    <td>{product.date.split('T')[0]}</td>
                    <td className="product-note">{product.note}</td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <>
              {this.state.crosslistedproducts.map((product, idx) => {
                return (
                  <tr>
                    <td>{idx + 1}</td>
                    <td>{product.title}</td>
                    <td>
                      {product.crossListedFrom.name}
                      <a
                        href={this.buildValidURL(product.crossListedFrom.url)}
                        target="_blank"
                      >
                        <BsLink45Deg />
                      </a>
                    </td>
                    <td>
                      <ul>
                        {product.crossListedTo.map((to) => {
                          return (
                            <li>
                              {to.name}
                              <a
                                href={this.buildValidURL(to.url)}
                                target="_blank"
                              >
                                <BsLink45Deg />
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </td>
                  </tr>
                );
              })}
            </>
          )}
        </table>

        <div className="w-50 mx-auto py-2">
          <Pagination
            curPage={this.state.page}
            totalPage={this.state.totalPage}
            rowPerPage={this.state.rowPerPage}
            next={(page) =>
              this.setState({ page: page }, () => this.fetchData())
            }
            previous={(page) =>
              this.setState({ page: page }, () => this.fetchData())
            }
            onRowNumberChange={(row) =>
              this.setState({ rowPerPage: row }, () => this.fetchData())
            }
          />
        </div>
        <AddCrossListedProductModal
          show={this.state.crossListingModalOpen}
          handleClose={() => this.handleCrossListingModalOpen(false)}
          getCrossListedProducts={this.getCrossListedProducts}
          clientid={clientid}
        />
      </div>
    );
  }
}
