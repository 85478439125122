import React from 'react';
import './SearchBar.css';

const SearchBar = (props) => {
  return (
    <div id="messagesSearchFilterBar" style={{ display: 'flex' }}>
      <div className="messages-client-search">
        <div style={{ display: 'inline-flex', flexGrow: '1' }}>
          <input
            className="messages-client-search-input"
            placeholder="Search..."
            onChange={(event) => props.setSearchText(event.target.value)}
          />
        </div>
        <button className="messages-client-search-button">
          <i className="fas fa-search"></i>
        </button>
      </div>
      <div style={{ display: 'inline-flex', padding: '10px' }}>
        <button className="messages-client-sections-collapse-button">
          <i
            className={
              props.oneOrBothSectionsExpanded
                ? 'far fa-minus-square'
                : 'far fa-plus-square'
            }
            style={{ fontSize: '1.5em' }}
            onClick={() =>
              props.expandOrCollapseBothSections(
                !props.oneOrBothSectionsExpanded
              )
            }
          ></i>
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
