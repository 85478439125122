import React, { useState, useEffect, useRef } from 'react';
import MessagesTab from './MessagesTab';
import './MessagesTabBar.css';
import { nanoid } from 'nanoid';

const MessagesTabBar = (props) => {
  const [tabs, setTabs] = useState([]);

  const tabBarContentAreaRef = useRef();

  useEffect(() => {
    let tempNewMessagesClients = props.newMessagesClients.filter(
      (newMessagesClient) =>
        tabs.filter(
          (tab) =>
            Object.keys(newMessagesClient)[0].split('|')[0] === tab.userId
        ).length > 0
    );

    setTabs((tabs) => [
      ...tabs.map((tab) => {
        let matchingClient = tempNewMessagesClients.filter(
          (tempNewMessagesClient) =>
            Object.keys(tempNewMessagesClient)[0].split('|')[0] === tab.userId
        );

        let matchingClient1 = props.clientAndTotalUnreadMessagesMap.filter(
          (c) => Object.keys(c)[0] === tab.userId
        );

        if (matchingClient1.length === 1) {
          tab = {
            ...tab,
            totalUnreadMessages: Object.values(matchingClient1[0])[0]
              .totalUnreadMessages,
          };
        }

        if (matchingClient.length === 1) {
          tab = {
            ...tab,
            totalNewMessagesAfterLogin: Object.values(
              matchingClient[0]
            )[0].filter(
              (message) =>
                message.messageStatus === 'UNREAD' &&
                message.isMessageFromClient === true
            ).length,
            totalUnreadMessages: Object.values(matchingClient[0])[0][
              Object.values(matchingClient[0])[0].length - 1
            ].totalUnreadMessages,
          };
        }

        if (props.selectedTab?.tabId === tab.userId) {
          tab = {
            ...tab,
            totalUnreadMessages: props.totalUnreadMessagesInSelectedTab,
          };
        }

        return tab;
      }),
    ]);
  }, [
    props.newMessagesClients,
    props.clientAndTotalUnreadMessagesMap,
    props.totalUnreadMessagesInSelectedTab,
    props.selectedTab?.tabId,
  ]);

  useEffect(() => {
    props.setNewMessagesClients((messagesClients) => [
      ...messagesClients.map((newMessagesClient) => {
        if (
          Object.keys(newMessagesClient)[0].split('|')[0] ===
          props.selectedTab?.tabId
        ) {
          return {
            [Object.keys(newMessagesClient)[0]]: [
              ...Object.values(newMessagesClient)[0].map((value, idx) => {
                if (Object.values(newMessagesClient)[0].length - 1 === idx) {
                  return {
                    ...Object.values(newMessagesClient)[0][
                      Object.values(newMessagesClient)[0].length - 1
                    ],
                    totalUnreadMessages: props.totalUnreadMessagesInSelectedTab,
                  };
                } else {
                  return value;
                }
              }),
            ],
          };
        } else {
          return newMessagesClient;
        }
      }),
    ]);
  }, [props.totalUnreadMessagesInSelectedTab]);

  useEffect(() => {
    if (props.addTab !== '') {
      tabBarContentAreaRef.current.scrollLeft = 0;
      setTabs((tabs) => {
        if (
          tabs.filter((tab) => tab.userId === props.addTab.userId).length === 0
        ) {
          tabs.unshift(props.addTab);
          props.setSelectedTab({
            tabName: props.addTab.userName,
            tabId: props.addTab.userId,
          });
          return [...tabs];
        } else {
          tabs = tabs.filter((tab) => tab.userId !== props.addTab.userId);
          tabs.unshift(props.addTab);
          props.setSelectedTab({
            tabName: props.addTab.userName,
            tabId: props.addTab.userId,
          });
          return [...tabs];
        }
      });
    }
  }, [props.addTab]);

  const deleteTab = (tabDetails) => {
    setTabs([...tabs.filter((tab) => tab.userId !== tabDetails.tabId)]);
    if (props.selectedTab.tabId === tabDetails.tabId) props.setSelectedTab('');
  };

  return (
    <div className="message-tabbar-container">
      <button
        className="previous-tab-button"
        onClick={() => (tabBarContentAreaRef.current.scrollLeft -= 40)}
      >
        <i className="fas fa-chevron-left"></i>
      </button>
      <div
        ref={tabBarContentAreaRef}
        className="message-tabbar-tab-content-area"
      >
        {tabs.map((tab) => {
          return (
            <MessagesTab
              key={nanoid(4)}
              tabDetails={tab}
              isSelected={
                tab.userName === props.selectedTab.tabName &&
                tab.userId === props.selectedTab.tabId
              }
              setSelectedTab={props.setSelectedTab}
              selectedTab={props.selectedTab}
              deleteTab={deleteTab}
            />
          );
        })}
      </div>
      <button
        className="next-tab-button"
        onClick={() => (tabBarContentAreaRef.current.scrollLeft += 40)}
      >
        <i className="fas fa-chevron-right"></i>
      </button>
    </div>
  );
};

export default MessagesTabBar;
