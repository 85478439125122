import React, { useState, useEffect, useRef } from 'react';
import './Messages.css';
import Axios from '../../services/Axios';
import OtherMessagesSection from './components/OtherMessagesSection';
import NewMessagesSection from './components/NewMessagesSection';
import SearchBar from './components/SearchBar';
import MessagesContentArea from './components/MessagesContentArea';
import jwt_decode from 'jwt-decode';

//Chat Image Gallery
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

const Messages = () => {
  const [newMessagesSectionHeight, setNewMessagesSectionHeight] = useState(0);
  const [otherMessagesSectionHeight, setOtherMessagesSectionHeight] =
    useState(0);
  const [
    initialOtherMessagesSectionHeight,
    setInitialOtherMessagesSectionHeight,
  ] = useState(0);
  const [maxNewMessagesSectionHeight, setMaxNewMessagesSectionHeight] =
    useState(0);
  const [newMessagesAccordianHeight, setNewMessagesAccordianHeight] =
    useState(0);
  const [otherMessagesAccordianHeight, setOtherMessagesAccordianHeight] =
    useState(0);
  const [newMessagesSectionExpanded, setNewMessagesSectionExpanded] =
    useState(true);
  const [otherMessagesSectionExpanded, setOtherMessagesSectionExpanded] =
    useState(true);
  const [oneOrBothSectionsExpanded, setOneOrBothSectionsExpanded] = useState(
    newMessagesSectionExpanded && otherMessagesSectionExpanded
  );
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedTab, setSelectedTab] = useState('');
  const [addTab, setAddTab] = useState('');
  const [newMessagesClients, setNewMessagesClients] = useState([]);
  const [clientAndTotalUnreadMessagesMap, setClientAndTotalUnreadMessagesMap] =
    useState([]);
  const [
    totalUnreadMessagesInSelectedTab,
    setTotalUnreadMessagesInSelectedTab,
  ] = useState(0);
  const [searchText, setSearchText] = useState('');

  //image show
  const [currentImage, setCurrentImage] = React.useState('');

  const [open, setOpen] = React.useState(false);

  const newMessagesSectionRef = useRef();
  const otherMessagesSectionRef = useRef();

  useEffect(() => {
    const joinRoom = async () => {
      const token = localStorage.getItem('token');
      const decoded = jwt_decode(token);
      const agentid = decoded._id;

      await Axios.post('/messagesNeo/joinRoom', {
        userType: 'AGENT',
        userId: agentid,
      });
    };

    let messagesContainer = document.getElementsByClassName(
      'client-list-container'
    )[0];
    let searchFilterBar = document.getElementById('messagesSearchFilterBar');
    let newMessagesSection = document.getElementById('newMessagesSection');

    setOtherMessagesSectionHeight(
      messagesContainer.offsetHeight -
        searchFilterBar.offsetHeight -
        newMessagesSection.offsetHeight -
        20
    );
    setInitialOtherMessagesSectionHeight(
      messagesContainer.offsetHeight -
        searchFilterBar.offsetHeight -
        newMessagesSection.offsetHeight -
        20
    );

    joinRoom();
  }, []);

  useEffect(() => {
    if (otherMessagesSectionHeight >= 0) {
      setOtherMessagesSectionHeight((otherMessagesSectionHeight) =>
        initialOtherMessagesSectionHeight - newMessagesSectionHeight <= 0
          ? 0
          : initialOtherMessagesSectionHeight - newMessagesSectionHeight
      );
    }
  }, [
    newMessagesSectionHeight,
    initialOtherMessagesSectionHeight,
    otherMessagesSectionHeight,
  ]);

  useEffect(() => {
    let messagesContainer = document.getElementsByClassName(
      'client-list-container'
    )[0];
    let searchFilterBar = document.getElementById('messagesSearchFilterBar');

    setMaxNewMessagesSectionHeight(
      messagesContainer.offsetHeight -
        searchFilterBar.offsetHeight -
        newMessagesAccordianHeight -
        otherMessagesAccordianHeight -
        10
    );
  }, [newMessagesAccordianHeight, otherMessagesAccordianHeight]);

  useEffect(() => {
    setOneOrBothSectionsExpanded(
      newMessagesSectionExpanded || otherMessagesSectionExpanded
    );
  }, [newMessagesSectionExpanded, otherMessagesSectionExpanded]);

  useEffect(() => {
    if (selectedClient !== '') {
      setAddTab(selectedClient);
    }
  }, [selectedClient]);

  const expandOrCollapseBothSections = (expandBothSections) => {
    if (expandBothSections === true) {
      setOneOrBothSectionsExpanded(true);
      setNewMessagesSectionExpanded(true);
      setOtherMessagesSectionExpanded(true);
    }

    if (expandBothSections === false) {
      setOneOrBothSectionsExpanded(false);
      setNewMessagesSectionExpanded(false);
      setOtherMessagesSectionExpanded(false);
    }
  };

  //image show

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentImage('');
  };

  const images = [
    {
      original: currentImage,
      thumbnail: currentImage,
    },
  ];

  useEffect(() => {
    if (currentImage) {
      handleClickOpen();
    }
  }, [currentImage]);

  return (
    <div className="container-fluid agent">
      <div className="messages-container">
        <div className="client-list-container">
          <SearchBar
            oneOrBothSectionsExpanded={oneOrBothSectionsExpanded}
            expandOrCollapseBothSections={expandOrCollapseBothSections}
            setSearchText={setSearchText}
          />
          <NewMessagesSection
            ref={newMessagesSectionRef}
            newMessagesSectionHeight={newMessagesSectionHeight}
            setNewMessagesSectionHeight={setNewMessagesSectionHeight}
            setNewMessagesAccordianHeight={setNewMessagesAccordianHeight}
            maxNewMessagesSectionHeight={maxNewMessagesSectionHeight}
            newMessagesSectionExpanded={newMessagesSectionExpanded}
            setNewMessagesSectionExpanded={setNewMessagesSectionExpanded}
            setSelectedClient={(value) => setSelectedClient(value)}
            selectedClient={selectedClient}
            newMessagesClients={newMessagesClients}
            setNewMessagesClients={setNewMessagesClients}
          />
          <OtherMessagesSection
            ref={otherMessagesSectionRef}
            otherMessagesSectionHeight={otherMessagesSectionHeight}
            setOtherMessagesAccordianHeight={setOtherMessagesAccordianHeight}
            otherMessagesSectionExpanded={otherMessagesSectionExpanded}
            setOtherMessagesSectionExpanded={setOtherMessagesSectionExpanded}
            setSelectedClient={(value) => setSelectedClient(value)}
            selectedClient={selectedClient}
            selectedTab={selectedTab}
            newMessagesClients={newMessagesClients}
            clientAndTotalUnreadMessagesMap={clientAndTotalUnreadMessagesMap}
            searchText={searchText}
          />
        </div>
        <MessagesContentArea
          addTab={addTab}
          selectedClient={selectedClient}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          newMessagesClients={newMessagesClients}
          setNewMessagesClients={setNewMessagesClients}
          clientAndTotalUnreadMessagesMap={clientAndTotalUnreadMessagesMap}
          setClientAndTotalUnreadMessagesMap={
            setClientAndTotalUnreadMessagesMap
          }
          setTotalUnreadMessagesInSelectedTab={
            setTotalUnreadMessagesInSelectedTab
          }
          totalUnreadMessagesInSelectedTab={totalUnreadMessagesInSelectedTab}
          setCurrentImage={setCurrentImage}
        />
        <>
          <div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Image Preview'}
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <div
                  style={{
                    width: '450px',
                    height: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <ImageGallery
                    items={images}
                    showPlayButton={false}
                    showThumbnails={false}
                  />
                </div>
              </DialogContent>
            </Dialog>
          </div>
          )
        </>
      </div>
    </div>
  );
};

export default Messages;
