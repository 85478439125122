/** @format */

import { useDispatch } from 'react-redux';
import { setPageSize } from './actions';

export const usePaginationActions = () => {
  const dispatch = useDispatch();

  /**
   *
   * Function that Set Page Size
   * @param {number} [pageSize] - Page Size
   * @return {*}  {void}
   */
  const doSetPageSize = async (pageSize) => {
    dispatch(setPageSize(pageSize));
  };

  // returns functions
  return {
    doSetPageSize,
  };
};
