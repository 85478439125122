import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import './searchcartmin.css';
import Search from '../utils/Search';
import Axios from '../../services/Axios';
import jwt_decode from 'jwt-decode';
import { nanoid } from 'nanoid';
import { Accordion, Card } from 'react-bootstrap';

const Searchcart = (props) => {
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.oldReducer.clients);
  const [search, setSearch] = useState('');
  const [searchedClients, setSearchClients] = useState('');
  const [archivedClients, setArchivedClients] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [highPriorityAsc, setHighPriorityAsc] = useState(false);
  const [medPriorityAsc, setMedPriorityAsc] = useState(false);
  const [lowPriorityAsc, setLowPriorityAsc] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeOnly, setActiveOnly] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      const decoded = jwt_decode(token);
      const agentid = decoded._id;
      var data = [];
      let archived = [];
      let unarchived = [];
      setLoading(true);

      await Axios.get(
        `/client/allocated/${agentid}?${activeOnly ? 'activeOnly=true' : ''}`
      )
        .then((response) => {
          console.log('clientsss', response.data);
          data = response.data;
          // data.sort(function(a,b){
          //   if(a.moreThan24< b.moreThan24) return 1;
          //   if(a.moreThan24 >b.moreThan24) return -1;
          //   if(a.between12and24< b.between12and24) return 1;
          //   if(a.between12and24 >b.between12and24) return -1;
          //   if(a.lessThan12< b.lessThan12) return 1;
          //   if(a.lessThan12 >b.lessThan12) return -1;
          //   return 0;
          // });
          console.log('mapping to archived', data);
          data.map((x) => {
            x.archived ? archived.push(x) : unarchived.push(x);
          });
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
      dispatch({ type: 'SET_CLIENTS', clients: [...unarchived] });
      setArchivedClients(archived);
    };
    fetchData();
  }, [activeOnly]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    setSearchClients(Search(clients, value));
  };

  const handleClear = (e) => {
    setSearch('');
    setSearchClients(clients);
  };

  const handleArchive = (e) => {
    Axios.post(`/client/archiveClient`, {
      userId: e.id,
      isArchive: e.isArchive,
    })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const prioritySort = (e) => {
    let data = searchedClients.length > 0 ? searchedClients : clients;

    function ascSort(property) {
      data.sort(function (a, b) {
        if (a[property] < b[property]) return 1;
        if (a[property] > b[property]) return -1;
        return 0;
      });
    }
    function descSort(property) {
      data.sort(function (a, b) {
        if (a[property] > b[property]) return 1;
        if (a[property] < b[property]) return -1;
        return 0;
      });
    }

    if (e.type == 'high' && e.ascOrder == true) ascSort('moreThan24');
    else if (e.type == 'high' && e.ascOrder == false) descSort('moreThan24');
    else if (e.type == 'medium' && e.ascOrder == true)
      ascSort('between12and24');
    else if (e.type == 'medium' && e.ascOrder == false)
      descSort('between12and24');
    else if (e.type == 'low' && e.ascOrder == true) ascSort('lessThan12');
    else if (e.type == 'low' && e.ascOrder == false) descSort('lessThan12');

    setSortedData((state) => {
      return { data };
    });
  };

  const fclient = searchedClients.length > 0 ? searchedClients : clients;
  const aclient = archivedClients;

  return (
    <>
      <div className="py-2 ">
        <div className="row">
          <input
            type="text"
            name="searcheverything"
            id="searcheverything"
            className="form-control ml-4 mr-3"
            onChange={handleSearchChange}
            value={search}
          ></input>
          <button
            type="button"
            className="btn btn-primary "
            id="buttonSize"
            onClick={handleClear}
          >
            CLEAR
          </button>

          <div className=" switch-container colum">
            <label>Active Only</label>
            <Switch
              onChange={() => {
                setActiveOnly(!activeOnly);
              }}
              inputProps={{ 'aria-label': 'Switch demo' }}
            />
          </div>
        </div>

        <table className="table users-table table-hover">
          <thead>
            <tr>
              <th>No</th>
              <th>User Name</th>
              <th>E-Mail</th>
              <th>Products</th>
              <th>Listed</th>
              <th>
                <button
                  className="btn btn-sm"
                  onClick={() => {
                    setHighPriorityAsc(!highPriorityAsc);
                    prioritySort({ type: 'high', ascOrder: highPriorityAsc });
                  }}
                >
                  {'Submitted more than 24hrs ago '}
                  <i
                    className={
                      highPriorityAsc ? 'fas fa-sort-up' : 'fas fa-sort-down'
                    }
                    aria-hidden="true"
                  ></i>
                </button>
              </th>
              <th>
                <button
                  className="btn btn-sm"
                  onClick={() => {
                    setMedPriorityAsc(!medPriorityAsc);
                    prioritySort({ type: 'medium', ascOrder: medPriorityAsc });
                  }}
                >
                  {'Submitted between 12-24hrs '}
                  <i
                    className={
                      medPriorityAsc ? 'fas fa-sort-up' : 'fas fa-sort-down'
                    }
                    aria-hidden="true"
                  ></i>
                </button>
              </th>
              <th>
                <button
                  className="btn btn-sm"
                  onClick={() => {
                    setLowPriorityAsc(!lowPriorityAsc);
                    prioritySort({ type: 'low', ascOrder: lowPriorityAsc });
                  }}
                >
                  {'Submitted less than 12hrs ago '}
                  <i
                    className={
                      lowPriorityAsc ? 'fas fa-sort-up' : 'fas fa-sort-down'
                    }
                    aria-hidden="true"
                  ></i>
                </button>
              </th>
              <th>Ebay Token</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {fclient ? (
              fclient.map((client, idx) => {
                var color;
                if (client.moreThan24 > 0) color = 'red';
                else color = 'black';
                return (
                  <React.Fragment key={nanoid(5)}>
                    <tr>
                      <td>{idx + 1}</td>
                      <th scope="row">
                        {client.firstName + ' ' + client.lastName}
                      </th>
                      <th scope="row">{client.email}</th>
                      <th scope="row">{client.noOfProducts}</th>
                      <th scope="row">{client.noOfListings}</th>
                      <td style={{ color: color }}>
                        {client.moreThan24 == 0 ? '-' : client.moreThan24}
                      </td>
                      <td>
                        {client.between12and24 == 0
                          ? '-'
                          : client.between12and24}
                      </td>
                      <td>
                        {client.lessThan12 == 0 ? '-' : client.lessThan12}
                      </td>
                      <th scope="row">
                        {client.ebayUserTokenExists ? (
                          client.hasActiveEbayToken ? (
                            <span className="badge badge-pill badge-success">
                              Active Token
                            </span>
                          ) : (
                            <span className="badge badge-pill badge-warning">
                              Inactive Token
                            </span>
                          )
                        ) : (
                          <span className="badge badge-pill badge-danger">
                            No User Token
                          </span>
                        )}
                      </th>
                      <td>
                        <button
                          onClick={() => {
                            props.history.push(`/passwords/${client._id}`);
                          }}
                          className="btn btn-primary btn-sm ml-3"
                        >
                          View Passwords
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            dispatch({ type: 'SET_CLIENT', client });
                            props.history.push(`/products/${client._id}`);
                          }}
                          className="btn btn-primary btn-sm ml-3"
                        >
                          View Products
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            props.history.push(
                              `/listingSettings/${client._id}`
                            );
                          }}
                          className="btn btn-primary btn-sm ml-3"
                        >
                          View Listing Settings
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            props.history.push(`/rule_engine/${client._id}`);
                          }}
                          className="btn btn-primary btn-sm ml-3"
                        >
                          Rule Engine
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            props.history.push(`/title_rule/${client._id}`);
                          }}
                          className="btn btn-primary btn-sm ml-3"
                        >
                          Title Rule
                        </button>
                      </td>
                      <td>
                        <button
                          value={client._id}
                          onClick={() => {
                            handleArchive({ id: client._id, isArchive: true });
                          }}
                          className="btn btn-light"
                        >
                          Clear
                        </button>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            ) : (
              <>
                <p1>No users found...</p1>
              </>
            )}
          </tbody>
        </table>

        {aclient.length != 0 ? (
          <Accordion>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                {'Archived Customers (' + aclient.length + ')'}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <table className="table users-table table-hover">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>User Name</th>
                        <th>E-Mail</th>
                        <th>Products</th>
                        <th>Listed</th>
                        <th>{'>24 hrs'}</th>
                        <th>{'12-24 hrs'}</th>
                        <th>{'<12 hrs'}</th>
                        <th>Ebay Token</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {aclient ? (
                        aclient.map((client, idx) => {
                          return (
                            <React.Fragment key={nanoid(5)}>
                              <tr>
                                <td>{idx + 1}</td>
                                <th scope="row">
                                  {client.firstName + ' ' + client.lastName}
                                </th>
                                <th scope="row">{client.email}</th>
                                <th scope="row">{client.noOfProducts}</th>
                                <th scope="row">{client.noOfListings}</th>
                                <td>{client.moreThan24}</td>
                                <td>{client.between12and24}</td>
                                <td>{client.lessThan12}</td>
                                <th scope="row">
                                  {client.ebayUserTokenExists ? (
                                    client.hasActiveEbayToken ? (
                                      <span className="badge badge-pill badge-success">
                                        Active Token
                                      </span>
                                    ) : (
                                      <span className="badge badge-pill badge-warning">
                                        Inactive Token
                                      </span>
                                    )
                                  ) : (
                                    <span className="badge badge-pill badge-danger">
                                      No User Token
                                    </span>
                                  )}
                                </th>
                                <td>
                                  <button
                                    value={client._id}
                                    onClick={() => {
                                      handleArchive({
                                        id: client._id,
                                        isArchive: false,
                                      });
                                    }}
                                    className="btn btn-light"
                                  >
                                    Restore
                                  </button>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <>
                          <p1>No users found...</p1>
                        </>
                      )}
                    </tbody>
                  </table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ) : null}
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Loading...
          </div>
        ) : null}
      </div>
    </>
  );
};
export default Searchcart;
