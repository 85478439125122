import React, { useState, useEffect } from 'react';
import Axios from '../../services/Axios';
import { ListingSettings } from '@hammoq-inc/hammoq-recycledcomponents-package';
import SweetAlerts from 'src/services/SweetAlerts';

const ListingSettingsPortal = (props) => {
  let { clientid } = props.match.params;

  return (
    <>
      <ListingSettings
        Axios={Axios}
        clientid={clientid}
        SweetAlerts={SweetAlerts}
      />
    </>
  );
};

export default ListingSettingsPortal;
