/** @format */

import produce from 'immer';
import { PaginationActionTypes } from './types';

const initialState = {
  pageSize: 10,
};

const setPageSize = (state, action) =>
  produce(state, (draft) => {
    const payload = action.payload;

    draft.pageSize = payload;
  });

export const PaginationReducer = (
  state = initialState,
  action = { type: 'UNKNOWN' }
) => {
  switch (action.type) {
    case PaginationActionTypes.SET_PAGE_SIZE:
      return setPageSize(state, action);

    default:
      return state;
  }
};
