import React, { useState, useEffect } from 'react';
import { TitleGenerator } from '@hammoq-inc/hammoq-recycledcomponents-package';

const TitleRulePortal = (props) => {
  let { clientid } = props.match.params;

  return (
    <>
      <TitleGenerator clientId={clientid} />
    </>
  );
};

export default TitleRulePortal;
