import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  Redirect,
} from 'react-router-dom';
import FilesUpload from './components/FilesUpload/FilesUpload';

import Searchcart from './components/searchcart/searchcart';
import Settings from './components/settings/settings';
import Login from './components/login/Login';
import 'font-awesome/css/font-awesome.min.css';
import ViewPassword from './components/passwords';
import ViewProduct from './components/Products';
import ListForm from './components/ListProduct/ListForm';
import Templates from './components/Templates/Templates';
import TemplateForm from './components/TemplateForm/TemplateForm';
import Header from './components/header/header';
import ResetPassword from './components/resetpassword/resetpassword';
import ListingSettingsPortal from './components/ListingSettings/ListingSettingsPortal';
import RuleEnginePortal from './components/RuleEngine/RuleEnginePortal';
import TitleRulePortal from './components/TitleRule/TitleRulePortal';
import { Messages, EbaySearch } from './Pages';

import { createBrowserHistory } from 'history';
//import tokenChecker from './services/interceptor';

//const history = createBrowserHistory();
//tokenChecker.setupInterceptors(history);

//comment
const Routes = withRouter((props) => {
  const { pathname } = props.history.location;
  return (
    <div>
      {!['/login', '/health'].includes(pathname) && (
        <div className="fixed-top">
          <Header />
          <FilesUpload />
        </div>
      )}
      <div className="container-fluid px-5 py-5 admin my-5">
        {localStorage.getItem('token') ? (
          <Switch>
            <Route exact path="/messages" component={Messages} />
            <Route exact path="/ebaysearch" component={EbaySearch} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <Route exact path="/searchcart" component={Searchcart} />
            <Route exact path="/templates" component={Templates} />
            <Route
              exact
              path="/template/:templateid"
              component={TemplateForm}
            />
            <Route exact path="/form" component={TemplateForm} />
            <Route exact path="/products/:clientid" component={ViewProduct} />
            <Route
              exact
              path="/product/:clientid/:productid"
              component={ListForm}
            />
            <Route exact path="/passwords/:clientid" component={ViewPassword} />
            <Route
              exact
              path="/listingSettings/:clientid"
              component={ListingSettingsPortal}
            />
            <Route
              exact
              path="/rule_engine/:clientid"
              component={RuleEnginePortal}
            />
            <Route
              exact
              path="/title_rule/:clientid"
              component={TitleRulePortal}
            />

            <Route exact path="/setting" component={Settings} />
            <Route path="/" component={() => <Redirect to="/searchcart" />} />
          </Switch>
        ) : (
          <Switch>
            <Route path="/health">
              <h3>The App is Healthy</h3>
            </Route>
            <Route exact path="/login" component={Login} />
            <Route path="/" component={() => <Redirect to="/login" />} />
          </Switch>
        )}
      </div>
    </div>
  );
});
function App() {
  return (
    <div>
      <Router>
        <Routes />
      </Router>
    </div>
  );
}

export default App;
