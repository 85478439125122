import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import './headermin.css';
import Logo from '../images/hammock_dashboard.svg';
import Axios from '../../services/Axios';
import jwt_decode from 'jwt-decode';

class Header extends Component {
  constructor() {
    super();
    this.state = {
      agent_id: '',
      totalMessages: 0,
    };
  }

  componentDidMount = async () => {
    if ('token' in localStorage) {
      const token = localStorage.getItem('token');
      const decoded = jwt_decode(token);
      const agentid = decoded._id;
      this.setState({ agent_id: agentid });
      var { totalMessages } = this.state;
      let totalMessagesMessage = await Axios.get(
        `/messagesNeo/messageunreadtotal/${agentid}/agent`
      );
      if (
        totalMessagesMessage.data &&
        totalMessagesMessage.data.totalUnreadMessage &&
        totalMessagesMessage.data.totalUnreadMessage[0] &&
        totalMessagesMessage.data.totalUnreadMessage[0].messages
      )
        totalMessages =
          totalMessagesMessage.data.totalUnreadMessage[0].messages;
      this.setState({ totalMessages });
    }
  };

  logoutHandler = () => {
    localStorage.removeItem('token');
    window.open('/login', '_self');
  };

  render() {
    const { totalMessages } = this.state;
    return (
      <nav
        className="navbar navbar-expand-lg navbar-dark"
        style={{ backgroundColor: '#102747' }}
      >
        <a href="/" className="navbar-brand">
          <img src={Logo} alt="hammock" height="40px" />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a
                href="/messages"
                className="nav-link"
                style={{ color: 'white' }}
              >
                Messages ({totalMessages})
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/ebaysearch"
                className="nav-link"
                style={{ color: 'white' }}
              >
                Ebay Search
              </a>
            </li>
            <li className="nav-item">
              <a
                href="/templates"
                className="nav-link"
                style={{ color: 'white' }}
              >
                Templates
              </a>
            </li>
            <a href="/setting" className="nav-link" style={{ color: 'white' }}>
              Setting
            </a>
            <li className="nav-item">
              <span
                onClick={this.logoutHandler}
                className="nav-link c-pointer text-danger"
              >
                <div className="fas fa-sign-out-alt mr-1"></div>
                Logout
              </span>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
export default Header;
