import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { AdvanceListing } from '@hammoq-inc/hammoq-recycledcomponents-package';
import SweetAlerts from '../../services/SweetAlerts';
import Axios, {
  get,
  post,
  put,
  baseURL,
  assetsURL,
  assetsThumbnailURL,
} from '../../services/Axios';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import IconButton from '@material-ui/core/IconButton';

class TemplateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateStatus: '.',
    };
  }

  goToPreviousPath = () => {
    this.props.history.goBack();
  };

  render = () => {
    const { templateid } = this.props.match.params;
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <IconButton aria-label="close" onClick={this.goToPreviousPath}>
            {/*RETURN BACK TO GOBACK FUNCTION.*/}
            <KeyboardBackspaceIcon />
          </IconButton>
        </div>
        <AdvanceListing
          get={get}
          post={post}
          put={put}
          baseURL={baseURL}
          assetsURL={assetsURL}
          assetsThumbnailURL={assetsThumbnailURL}
          Axios={Axios}
          SweetAlerts={SweetAlerts}
          productid={templateid}
          isTemplate={true}
        />
      </>
    );
  };
}

export default TemplateForm;
