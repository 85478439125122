import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import './services/images.jsx';
import store from './redux/store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { initializeDataDog, logger } from './datadog';

initializeDataDog({
  clientToken: 'pub8ef44e1407aa7fcc8b53dc55db8300ff',
  applicationId: '3f2a1381-0c22-40c4-9786-6c9ad4f8d490',
  serviceName: 'hammoq-agent-client',
  appVersion: process.env.REACT_APP_SHA || 'no-version-local',
  trackSessionAcrossSubdomains: true,
});

logger.info('application starting up');

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// Build
