import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Axios from '../services/Axios';
import {
  Confirmer,
  ErrorToaster,
  SuccessToaster,
} from '../services/SweetAlerts';

export default function AddCrossListedProductModal({
  show,
  handleClose,
  getCrossListedProducts,
  clientid,
}) {
  const [, reRender] = useState({});
  const [title, setTitle] = useState('');
  const [fromSite, setFromSite] = useState({ name: '', url: '' });
  const [toSites, setToSites] = useState([{ name: '', url: '' }]);
  const [message, setMessage] = useState({ type: '', text: '', show: false });
  const [clientInfo, setClientInfo] = useState({});
  const [rates, setRates] = useState({});
  let token = localStorage.getItem('token');
  const { _id: agentId } = (token && jwt_decode(token)) || {};

  const resetForm = () => {
    setTitle('');
    setFromSite({ name: '', url: '' });
    setToSites([{ name: '', url: '' }]);
  };

  const getClientInfo = async () => {
    try {
      const clientInfoResponse = await Axios.get(
        `/client/clientinfo/${clientid}`
      );

      setClientInfo(clientInfoResponse);
    } catch (err) {
      ErrorToaster.fire({ text: 'Error in getting client info' });
    }
  };

  const getRateInfo = async () => {
    try {
      const rateResponse = await Axios.post('/payment/rates', {
        clientId: clientid,
      });
      setRates(rateResponse.data.rate);
    } catch (err) {
      ErrorToaster.fire({ text: 'Error in getting client rates' });
    }
  };

  useEffect(() => {
    getClientInfo();
    getRateInfo();
  }, []);

  const addToSite = () => {
    setToSites([...toSites, { name: '', url: '' }]);
  };
  const removeToSite = (idx) => {
    toSites.splice(idx, 1);
    setToSites(toSites);
    reRender({});
  };

  const handleToSiteNameURLChange = (type, value, idx) => {
    //type : name or URL
    toSites[idx][type] = value;
    setToSites(toSites);
    reRender({});
  };

  const handleFromSiteNameURLChange = (type, value) => {
    //type : name or URL
    fromSite[type] = value;
    setFromSite(fromSite);
    reRender({});
  };

  const handleSubmit = async () => {
    try {
      await getRateInfo();
      if (title == '') {
        setMessage({ type: 'danger', text: 'Title is required', show: true });
        return;
      }
      if (fromSite.name == '') {
        setMessage({
          type: 'danger',
          text: 'From Site Name is required',
          show: true,
        });
        return;
      }
      if (fromSite.url == '') {
        setMessage({
          type: 'danger',
          text: 'From Site URL is required',
          show: true,
        });
        return;
      }
      for (let idx = 0; idx < toSites.length; idx++) {
        if (toSites[idx].name == '') {
          setMessage({
            type: 'danger',
            text: 'To Site Name is required',
            show: true,
          });
          return;
        }
        if (toSites[idx].url == '') {
          setMessage({
            type: 'danger',
            text: 'To Site URL is required',
            show: true,
          });
          return;
        }
      }
      const totalCost = (toSites.length * rates.crossList) / 100;
      const balance = clientInfo.balance;

      if (totalCost > balance) {
        return Confirmer.fire({
          text: `Insufficient balance,\nAccount balance : ${clientInfo.balance} \nRequired balance : ${totalCost}`,
        });
      }
      if (balance < 5) {
        const balanceConfirm = await Confirmer.fire({
          text: `Low user balance, user has ${balance}. Do you want to continue!`,
        });
        if (!balanceConfirm.isConfirmed) return;
      }
      const crossListingConfirm = await Confirmer.fire({
        text: `Are you sure, This will create a new crosslisting for the user and charge them for $${totalCost} for crosslisting product on ${
          toSites.length
        } sites at a rate of $${rates.crossList / 100} per site.`,
      });

      if (!crossListingConfirm.isConfirmed) return;

      const FormData = {
        userId: clientid,
        title,
        crossListedBy: agentId,
        crossListedTo: toSites,
        crossListedFrom: fromSite,
      };
      const response = await Axios.post(
        '/product/crosslistedproduct',
        FormData
      );
      SuccessToaster.fire(response.data.msg);

      resetForm();
      getCrossListedProducts();
      getClientInfo();
    } catch (err) {
      ErrorToaster.fire({ text: 'Error in creating crosslisting' });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add New Cross Listing Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-0">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Title:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Title of Product"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </Form.Group>
        </Row>
        <Row className="mb-0">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>From Site Name:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name of site from which product is crosslisted"
              value={fromSite.name}
              onChange={(e) => {
                handleFromSiteNameURLChange('name', e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>From Site Product URL:</Form.Label>
            <Form.Control
              type="text"
              placeholder="URL of product"
              value={fromSite.url}
              onChange={(e) => {
                handleFromSiteNameURLChange('url', e.target.value);
              }}
            />
          </Form.Group>
        </Row>
        <Row className="mb-0">
          <div className="d-flex justify-content-between w-100 pr-3">
            <Form.Group as={Col} className="mb-0" controlId="formGridEmail">
              <Form.Label>To Site(s):</Form.Label>
            </Form.Group>
            <div>
              <button className="btn  btn-outline-primary" onClick={addToSite}>
                +
              </button>
            </div>
          </div>
        </Row>
        {toSites.map((toSite, idx) => {
          return (
            <Row key={idx} className="mb-0">
              <Form.Group as={Col} sm={5} controlId="formGridEmail">
                <Form.Label>Site Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name of site to which product is crosslisted"
                  value={toSite.name}
                  onChange={(e) => {
                    handleToSiteNameURLChange('name', e.target.value, idx);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} sm={6} controlId="formGridEmail">
                <Form.Label>URL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="URL of product"
                  value={toSite.url}
                  onChange={(e) => {
                    handleToSiteNameURLChange('url', e.target.value, idx);
                  }}
                />
              </Form.Group>
              <Col
                sm={1}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <button
                  className="btn btn-outline-danger"
                  style={{ borderRadius: '50%' }}
                  onClick={() => {
                    removeToSite(idx);
                  }}
                >
                  -
                </button>
              </Col>
            </Row>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        {message.show && (
          <div className="d-flex justfy-content-start w-100">
            <small className={`text-${message.type}`}>{message.text}</small>
          </div>
        )}
        <div className="d-flex justify-content-around w-100">
          <button className="btn btn-outline-danger" onClick={handleClose}>
            Close
          </button>
          <button className="btn btn-outline-primary" onClick={handleSubmit}>
            Add CrossListing
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
