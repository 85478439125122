import React, { useRef, useEffect } from 'react';
import Tippy from '@tippyjs/react';
import './OtherMessagesSectionAccordian.css';
import OtherMessagesFilterSort from './OtherMessagesFilterSort';
import { nanoid } from 'nanoid';

const OtherMessagesSectionAccordian = (props) => {
  const accordianRef = useRef();

  useEffect(() => {
    props.setOtherMessagesAccordianHeight(accordianRef.current?.offsetHeight);
  }, []);

  return (
    <>
      <div
        ref={accordianRef}
        className="other-messages-collapsible-section-accordian"
      >
        <div
          onClick={() => props.handleCollapse(!props.isCollapsed)}
          className="other-messages-collapsible-section-accordian-name"
        >
          <span
            id="other-messages-section-chevron"
            style={{ margin: '0px 10px', fontSize: '1em' }}
          >
            {props.isCollapsed === false ? (
              <i className="fas fa-chevron-down"></i>
            ) : (
              <i className="fas fa-chevron-right"></i>
            )}
          </span>
          <span style={{ fontSize: '0.8em', userSelect: 'none' }}>
            Other Messages
          </span>
        </div>
        <span>
          <Tippy
            theme="material-theme"
            animation="scale"
            arrow={false}
            trigger="click"
            interactive={true}
            interactiveBorder={30}
            allowHTML={true}
            zIndex={9999999999}
            strategy="fixed"
            placement="bottom"
            maxWidth={450}
            content={
              <OtherMessagesFilterSort
                setFilterType={props.setFilterType}
                filterType={props.filterType}
              />
            }
          >
            <button className="other-messages-accordian-button">
              <i
                className="fas fa-filter"
                style={{
                  color: props.filterType.length === 0 ? '#508AA8' : '#D16666',
                }}
              ></i>
            </button>
          </Tippy>
          <button
            className="other-messages-accordian-button"
            onClick={() => props.setRefreshOtherMessagesClientList(nanoid(5))}
          >
            <i className="fas fa-sync" style={{ color: '#0CCA4A' }}></i>
          </button>
        </span>
      </div>
    </>
  );
};

export default OtherMessagesSectionAccordian;
