import Swal from 'sweetalert2';

export const Confirmer = Swal.mixin({
  icon: 'warning',
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger',
  },
});

export const ErrorToaster = Swal.mixin({
  icon: 'error',
  position: 'top-start',
  toast: true,
  timer: 4000,
  showConfirmButton: false,
});

export const SuccessToaster = Swal.mixin({
  icon: 'success',
  position: 'top-start',
  toast: true,
  timer: 4000,
  showConfirmButton: false,
});
export const MultiErrorToaster = Swal.mixin({
  html: 'Please check these <b>errors</b> below <ul style="text-align: left; color:crimson;">%s</ul>',
});
export default {
  SuccessToaster,
  ErrorToaster,
  MultiErrorToaster,
  Confirmer,
};
