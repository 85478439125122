import { EbayProductSearch } from '@hammoq-inc/hammoq-recycledcomponents-package';

import React, { Component } from 'react';
import Axios from '../../services/Axios';

class EbayProductSearchPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    return (
      <>
        <EbayProductSearch leftTop={'80px'} Axios={Axios} />
      </>
    );
  };
}

export default EbayProductSearchPortal;
