import { AdvanceListing } from '@hammoq-inc/hammoq-recycledcomponents-package';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useProductsActions, useProductsData } from '../../redux/Products';
import Axios, {
  assetsThumbnailURL,
  assetsURL,
  baseURL,
  get,
  post,
  put,
} from '../../services/Axios';
import SweetAlerts from '../../services/SweetAlerts';

const ListForm = ({ match }) => {
  const history = useHistory();
  const state = useSelector((state) => state);
  const { productIds, currentPage, totalPage, rowsPerPage, prodStatus } =
    useProductsData();
  const { doUpdateProductsIdsAndPaginationData } = useProductsActions();
  const { clientid, productid } = match.params;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [productid]);

  const goToPreviousPath = () => {
    history.goBack();
  };

  const getProducts = async (page, size, search, prodStatus) => {
    const response = await Axios.get(
      `/product/${clientid}/type/${prodStatus}`,
      {
        params: {
          page: page,
          size: size,
          search: search,
        },
      }
    );

    return response.data.data.filter((status) => status.status !== false);
  };

  const unRecognizedIdHandler = async (currentId, currentProduct) => {
    try {
      const newProducts = await getProducts(
        currentPage ? currentPage + 1 : 1,
        rowsPerPage || 500,
        '',
        prodStatus && prodStatus !== '' ? prodStatus : currentProduct.prodStatus
      );

      if (newProducts.length === 0) {
        SweetAlerts.ErrorToaster.fire('There is no more products');
        return;
      }

      doUpdateProductsIdsAndPaginationData(
        newProducts,
        currentPage ? currentPage + 1 : 1,
        totalPage,
        rowsPerPage || 500,
        prodStatus && prodStatus !== '' ? prodStatus : currentProduct.prodStatus
      );

      const updatedIndex = newProducts.findIndex(
        (n) => n._id.toString() === currentId
      );

      let id = '';

      if (updatedIndex !== -1) {
        const newProduct = newProducts[updatedIndex + 1];

        id = newProduct._id.toString();
      } else {
        const newProduct = newProducts[0];
        id = newProduct._id.toString();
      }

      const route = `/product/${clientid}/${id}`;
      history.push(route);
    } catch (error) {
      SweetAlerts.ErrorToaster.fire('Something went wrong.');
      return;
    }
  };

  const nextHandler = (product) => {
    const { productid: currentId } = match.params;

    const currentIndex = productIds.findIndex((pId) => pId === currentId);

    // check the edge case
    if (currentIndex === -1 || currentIndex === productIds.length - 1) {
      return unRecognizedIdHandler(currentId, product);
    }

    // get the next one
    else history.push(`/product/${clientid}/${productIds[currentIndex + 1]}`);
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <IconButton aria-label="close" onClick={goToPreviousPath}>
          {/*RETURN BACK TO GOBACK FUNCTION.*/}
          <KeyboardBackspaceIcon />
        </IconButton>
      </div>
      <AdvanceListing
        assetsURL={assetsURL}
        assetsThumbnailURL={assetsThumbnailURL}
        baseURL={baseURL}
        Axios={Axios}
        get={get}
        post={post}
        put={put}
        SweetAlerts={SweetAlerts}
        clientid={clientid}
        productid={productid}
        client={{}}
        onNext={nextHandler}
        canApproveListing={false}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return { client: state.client };
};
export default ListForm;
