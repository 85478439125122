import React, { useState, useEffect, useRef } from 'react';
import Axios from '../../../../../services/Axios';
import './OtherMessagesClientList.css';
import { nanoid } from 'nanoid';

let cancelToken;

const OtherMessagesClientList = (props) => {
  const [clients, setClients] = useState([]);
  const [hiddenClientVisibility, setHiddenClientVisibility] =
    useState('hidden');
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(0);
  const [initialHiddenClientOffsetHeight, setInitialHiddenClientOffsetHeight] =
    useState(0);
  const [scrollLock, setScrollLock] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filterType, setFilterType] = useState('');
  const [previousLimit, setPreviousLimit] = useState(0);
  const [refreshClientList, setRefreshClientList] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);

  const otherMessagesClientListRef = useRef();

  const getUrl = () => {
    let url = '/messagesNeo/otherMessagesClients?&limit=' + limit;
    url = url + '&offset=' + offset;
    url = url + '&searchText=' + searchText;
    url = url + '&filterType=' + filterType;
    url = url + '&refreshClientList=' + refreshClientList;
    return url;
  };

  useEffect(() => {
    if (props.searchText.length > 0) {
      setOffset(0);
      setClients([]);
    } else {
      setOffset(0);
      setClients([]);
    }

    if (props.filterType.length > 0) {
      setOffset(0);
      setClients([]);
    } else {
      setOffset(0);
      setClients([]);
    }

    if (props.refreshOtherMessagesClientList.length > 0) {
      setOffset(0);
      setClients([]);
    } else {
      setOffset(0);
      setClients([]);
    }
  }, [
    props.searchText,
    props.filterType,
    props.refreshOtherMessagesClientList,
  ]);

  useEffect(() => {
    if (clients.length === 0) {
      setSearchText(props.searchText);
      setFilterType(props.filterType);
      setRefreshClientList(props.refreshOtherMessagesClientList);
    }
  }, [
    clients,
    props.filterType,
    props.refreshOtherMessagesClientList,
    props.searchText,
  ]);

  useEffect(() => {
    if (limit > 0) {
      setHiddenClientVisibility('none');
    }
  }, [limit]);

  useEffect(() => {
    getClients();
  }, [getUrl()]);

  useEffect(() => {
    if (
      otherMessagesClientListRef?.current?.scrollHeight >
      otherMessagesClientListRef?.current?.clientHeight
    )
      return;

    if (initialHiddenClientOffsetHeight > 0) {
      setLimit(
        parseInt(
          document.getElementsByClassName('other-messages-client-list')[0]
            .offsetHeight / initialHiddenClientOffsetHeight
        ) + 1
      );
    }

    if (document.getElementById('hidden-client')?.offsetHeight > 0) {
      setInitialHiddenClientOffsetHeight(
        document.getElementById('hidden-client').offsetHeight
      );
      setLimit(
        parseInt(
          document.getElementsByClassName('other-messages-client-list')[0]
            .offsetHeight /
            document.getElementById('hidden-client').offsetHeight
        ) + 1
      );
    }
  }, [props.otherMessagesSectionHeight, initialHiddenClientOffsetHeight]);

  const getClients = async () => {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }

    cancelToken = Axios.CancelToken.source();

    setPreviousLimit(limit);
    setIsLoading(true);
    setNoResults(false);

    let res = await Axios.get(getUrl(), {
      cancelToken: cancelToken.token,
    }).then((res) => {
      setIsLoading(false);
      if (res.data.clients.length == 0) {
        setNoResults(true);
      }
      return res;
    });

    if (previousLimit === limit) setClients([...clients, ...res.data.clients]);
    else setClients([...res.data.clients]);

    setScrollLock(false);
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom && scrollLock === false) {
      setScrollLock(true);
      setOffset((offset) => offset + limit);
    }
  };

  const calculateTotalNewMessagesAfterLogin = (clientId) => {
    let totalNewMessagesAfterLogin = 0;

    let clientMessages = props.newMessagesClients.filter(
      (messagesClient) =>
        Object.keys(messagesClient)[0].split('|')[0] === clientId
    )[0];

    if (clientMessages) {
      totalNewMessagesAfterLogin = Object.values(clientMessages)[0].filter(
        (message) =>
          message.messageStatus === 'UNREAD' &&
          message.isMessageFromClient === true
      ).length;

      return totalNewMessagesAfterLogin;
    } else {
      return totalNewMessagesAfterLogin;
    }
  };

  const calculateTotalUnreadMessages = (clientId) => {
    let totalUnreadMessages = 0;

    let clientMessages = props.newMessagesClients.filter(
      (messagesClient) =>
        Object.keys(messagesClient)[0].split('|')[0] === clientId
    )[0];

    if (clientMessages) {
      let messagesFromClient = Object.values(clientMessages)[0].filter(
        (message) => message.isMessageFromClient
      );

      totalUnreadMessages =
        messagesFromClient[messagesFromClient.length - 1]
          ?.totalUnreadMessages || 0;

      return totalUnreadMessages;
    } else {
      return totalUnreadMessages;
    }
  };

  const getTotalUnreadMessages = (client) => {
    let totalUnreadMessages = calculateTotalUnreadMessages(client.client._id);

    totalUnreadMessages =
      totalUnreadMessages > 0
        ? totalUnreadMessages
        : client.totalUnreadMessages;

    if (isNaN(totalUnreadMessages)) return 0;
    else return totalUnreadMessages;
  };

  return (
    <>
      <div
        ref={otherMessagesClientListRef}
        className="other-messages-client-list"
        style={{ height: props.otherMessagesSectionHeight + 'px' }}
        onScroll={handleScroll}
      >
        <div
          id="hidden-client"
          className="messages-client-li"
          style={{
            visibility: hiddenClientVisibility,
            display: hiddenClientVisibility,
          }}
        >
          <span className="messages-user-icon-container">
            <i
              className="far fa-user-circle fa-2x"
              style={{ verticalAlign: 'middle' }}
            ></i>
          </span>
          &nbsp;&nbsp;<span>Hidden Client</span>
        </div>
        {clients.length != 0 ? null : isLoading ? (
          <div> &nbsp;Loading...</div>
        ) : noResults ? (
          <div> &nbsp;No results found.</div>
        ) : null}
        {clients.map((client) => (
          <div
            key={nanoid(4)}
            onClick={(event) =>
              props.setSelectedClient({
                userName: client.client.userName,
                userId: client.client._id,
                section: 'OTHER_MESSAGES',
                totalNewMessagesAfterLogin: calculateTotalNewMessagesAfterLogin(
                  client.client._id
                ),
                totalUnreadMessages: getTotalUnreadMessages(client),
              })
            }
            className={
              props.selectedClient.userId === client.client._id &&
              props.selectedClient.section === 'OTHER_MESSAGES'
                ? 'other-messages-client-li-focus'
                : 'other-messages-client-li'
            }
          >
            <span className="messages-user-icon-container">
              <i
                className="far fa-user-circle fa-2x"
                style={{
                  color:
                    props.selectedClient.userId === client.client._id &&
                    props.selectedClient.section === 'OTHER_MESSAGES'
                      ? 'white'
                      : 'black',
                  verticalAlign: 'middle',
                }}
              ></i>
            </span>
            <span style={{ userSelect: 'none', fontWeight: '450' }}>
              &nbsp;&nbsp;{client.client.userName}
            </span>
            <span style={{ float: 'right' }}>
              {getTotalUnreadMessages(client) > 0 ? (
                <span
                  style={{
                    backgroundColor: '#18A999',
                    color: 'white',
                    padding: '2px 10px',
                    fontWeight: '400',
                    margin: '0px 7px',
                  }}
                >
                  {getTotalUnreadMessages(client)}
                </span>
              ) : null}
              {calculateTotalNewMessagesAfterLogin(client.client._id) > 0 ? (
                <span
                  style={{
                    backgroundColor: '#E71D36',
                    color: 'white',
                    padding: '2px 6px',
                    fontWeight: '400',
                    margin: '0px 7px',
                  }}
                >
                  {'+' + calculateTotalNewMessagesAfterLogin(client.client._id)}
                </span>
              ) : null}
            </span>
          </div>
        ))}
        {clients.length == 0 ? null : isLoading ? (
          <div> &nbsp; Loading...</div>
        ) : null}
      </div>
    </>
  );
};

export default OtherMessagesClientList;
