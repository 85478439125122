import React from 'react';
import PropTypes from 'prop-types';
import constants from '../../../utils/constants';
import { getProductMarketPlaces } from '../../../utils/methods';

const StatusColumn = ({ product }) => {
  const marketplaces = getProductMarketPlaces(product);
  const listed = marketplaces.filter((o) => o.listed);

  const isDefault =
    product?.prodStatus === constants.PRODUCT_STATUSES.INVENTORY &&
    !listed.length;

  if (isDefault) {
    return (
      <div>
        <small>Listed</small>
      </div>
    );
  }

  return marketplaces.map(({ listed, message, value }, index) => {
    return (
      <div key={`status-${index}`}>
        {listed ? (
          <a href={value.url} target="_blank" rel="noreferrer">
            {message}
          </a>
        ) : (
          <p className="text-danger">{message}</p>
        )}
      </div>
    );
  });
};

StatusColumn.propTypes = {
  product: PropTypes.object.isRequired,
};

StatusColumn.defaultProps = {
  product: {},
};

export default StatusColumn;
