import React, { useState } from 'react';
import Axios from '../../../../services/Axios';
import './MessagesTextArea.css';
import jwt_decode from 'jwt-decode';
import Grid from '@material-ui/core/Grid';

const MessagesTextArea = (props) => {
  const [message, setMessage] = useState('');
  const [uploadedFile, setUploadedFile] = useState([]);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [sending, setSending] = useState(false);

  const sendMessage = async () => {
    if (uploadingFile) return;

    if (uploadedFile.length > 0) {
      setSending(true);
      const token = localStorage.getItem('token');
      const decoded = jwt_decode(token);
      const agentid = decoded._id;

      uploadedFile.forEach(async (file) => {
        await Axios.post('/messagesNeo/sendMessage', {
          message: { messageType: 'File', messageBody: file.trim() },
          fromUserId: agentid,
          toUserIds: [props.selectedTab.tabId],
        }).then(() => {
          setSending(false);
        });
      });
    }

    if (message.trim().length > 0) {
      setSending(true);
      const token = localStorage.getItem('token');
      const decoded = jwt_decode(token);
      const agentid = decoded._id;

      await Axios.post('/messagesNeo/sendMessage', {
        message: { messageType: 'Text', messageBody: message.trim() },
        fromUserId: agentid,
        toUserIds: [props.selectedTab.tabId],
      }).then(() => {
        setSending(false);
      });
    }
    setMessage('');
    setUploadedFile([]);
    props.refresh();
    return;
  };

  const onChangeHandler = async (event) => {
    // setMessage('')
    setUploadingFile(true);
    Object.values(event.target.files).forEach(async (file) => {
      const data = new FormData();
      data.append('file', file);
      let res = await Axios.post('/messagesNeo/uploadFile', data);
      setUploadedFile((oldFiles) => [...oldFiles, res?.data?.publicUrl || '']);
      setUploadingFile(false);
    });
  };

  return (
    <div className="message-textarea-container">
      <div className="message-textarea">
        <textarea
          className="message-textarea-input"
          placeholder="Message HAMMOQ support"
          value={message}
          onChange={(event) => {
            setMessage(event.target.value);
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              event.stopPropagation();
              sendMessage();
            }
          }}
          disabled={sending}
        />
      </div>
      <Grid container>
        <Grid item xs={12}>
          <div className="message-textarea-controls">
            <span className="message-icons-right">
              <button
                className="send-message-button"
                onClick={sendMessage}
                disabled={sending}
              >
                <i
                  className="fas fa-paper-plane"
                  style={{ margin: '5px', color: 'white' }}
                ></i>
              </button>
            </span>
            <span className="message-icons">
              {uploadedFile.length > 0
                ? uploadedFile.map((file, index) => {
                    return (
                      <img
                        alt="uploadedPhoto"
                        src={file}
                        height={35}
                        width={35}
                        style={{ marginLeft: '10px' }}
                      ></img>
                    );
                  })
                : null}
              {uploadingFile === true ? (
                <i
                  className="fa fa-upload"
                  aria-hidden="true"
                  style={{ marginLeft: '10px', color: '#8E7DBE' }}
                />
              ) : null}
              <button
                className="message-attachment-button"
                style={{ display: 'inline-flex', padding: '5px' }}
                disabled={uploadingFile}
              >
                <span style={{ position: 'relative' }}>
                  <input
                    type="file"
                    name="file"
                    multiple
                    accept="image/*"
                    onChange={onChangeHandler}
                    onClick={(event) => (event.target.value = '')}
                    style={{ opacity: 0, position: 'absolute', width: '100%' }}
                  />
                  <i
                    className="fas fa-paperclip"
                    style={{ margin: '5px', color: 'white' }}
                  />
                </span>
              </button>
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MessagesTextArea;
