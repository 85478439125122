class DateConverter {
  /**
   * Converts a date to a string
   * @param {Date | string} date
   * @returns {string}
   */
  static toLongString(date) {
    let purifiedDate = date;

    // convert string to date
    if (typeof date === 'string') purifiedDate = new Date(date);
    else if (!(date instanceof Date))
      throw new Error(
        'DateConverter.toLongString: date must be a Date or a string'
      );

    var options = {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };

    return purifiedDate.toLocaleDateString('en-US', options);
  }
}

export default DateConverter;
