/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import { useEffect } from 'react';

export interface Data {
  image: string;
  title: string;
}
interface EnhancedTableProps {
  products: any[];
  rowsPerPage: number;
  onHandleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onHandleChangePage: (event: unknown, newPage: number) => void;
  page: number;
  totalCount: number;
  onHandleAddProductIds: (productIds: string[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);
const EnhancedTable = ({
  products,
  rowsPerPage,
  page,
  totalCount,
  onHandleAddProductIds,
  onHandleChangeRowsPerPage,
  onHandleChangePage,
}: EnhancedTableProps) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState<string[]>([]);
  const [totalCountPages, setTotalCountPages] = React.useState(totalCount);
  const [checked, setChecked] = React.useState(true);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.checked);
    if (checked) {
      const newSelectees = products
        .filter((m) => !m.inOurSystem)
        .map((n) => n.id);
      setChecked(false);
      setSelected(newSelectees);
      return;
    }
    setSelected([]);
    setChecked(true);
  };

  useEffect(() => {
    console.log(selected);
  }, [selected]);

  const handleClick = (
    event: React.MouseEvent<unknown>,
    id: string,
    inOurSystem: boolean
  ) => {
    if (inOurSystem) {
      return false;
    }
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, products.length - page * rowsPerPage);

  useEffect(() => {
    if (selected && selected.length > 0) {
      onHandleAddProductIds(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (totalCountPages !== products.length) {
      totalCount === products.length
        ? setTotalCountPages(products.length)
        : setTotalCountPages(totalCount);
    } else {
      setTotalCountPages(products.length);
    }
  }, [products]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={products.length}
            />
            <TableBody>
              {products
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return !row?.multiVariant ? (
                    <TableRow
                      hover
                      onClick={(event) =>
                        handleClick(event, row.id, row.inOurSystem)
                      }
                      role="checkbox"
                      aria-checked={isItemSelected && !row.inOurSystem}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      style={
                        row.inOurSystem
                          ? { pointerEvents: 'none', opacity: '0.4' }
                          : {}
                      }
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected && !row.inOurSystem}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <img
                          src={row.image}
                          className="product-img"
                          alt={row.name}
                          style={{ padding: '10px' }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <div style={{ paddingLeft: '45%' }}>{row.title}</div>
                      </TableCell>
                    </TableRow>
                  ) : null;
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={totalCountPages}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onHandleChangePage}
          onRowsPerPageChange={onHandleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};
export default EnhancedTable;
