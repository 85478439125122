import React, { useState, useEffect } from 'react';
import { RuleEngine } from '@hammoq-inc/hammoq-recycledcomponents-package';

const RuleEnginePortal = (props) => {
  let { clientid } = props.match.params;

  return (
    <>
      <RuleEngine clientid={clientid} />
    </>
  );
};

export default RuleEnginePortal;
