/** @format */
import { ProductsActionTypes } from './types';

export const setProductIds = ({
  products,
  currentPage,
  totalPage,
  rowsPerPage,
  prodStatus,
}) => ({
  type: ProductsActionTypes.SET_PRODUCT_IDS,
  payload: { products, currentPage, totalPage, rowsPerPage, prodStatus },
});

export const updateProductIds = ({
  products,
  currentPage,
  totalPage,
  rowsPerPage,
  prodStatus,
}) => ({
  type: ProductsActionTypes.UPDATE_PRODUCT_IDS,
  payload: { products, currentPage, totalPage, rowsPerPage, prodStatus },
});
