export default {
  MARKETPLACES: [
    {
      key: 'ebay',
      label: 'Ebay',
    },
    {
      key: 'facebook',
      label: 'Facebook',
    },
    {
      key: 'poshmark',
      label: 'Poshmark',
    },
    {
      key: 'mercari',
      label: 'Mercari',
    },
    {
      key: 'shopify',
      label: 'Shopify',
    },
    { key: 'others', label: 'Others' },
  ],
  STATUSES: {
    LISTED: 'Listed',
    NOT_LISTED: 'Not yet listed',
  },
  PRODUCT_STATUSES: {
    SUBMITTED: 'submitted',
    DRAFT: 'draft',
    CONFLICTED: 'conflicted',
    APPROVED: 'approved_drafts',
    INVENTORY: 'inventory',
  },
};
