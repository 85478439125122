import React, { Component } from 'react';
import './settingsmin.css';
import { Link } from 'react-router-dom';
import Axios from '../../services/Axios';
import jwt_decode from 'jwt-decode';

class settings extends Component {
  constructor() {
    super();
    this.state = {
      username: '',
      email: '',
    };
  }

  logoutHandler = () => {
    localStorage.removeItem('token');
    window.open('/login', '_self');
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  editinfo = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const decoded = jwt_decode(token);
    const agentid = decoded._id;
    Axios.post(`/agentdetail/${agentid}`, {
      name: this.state.username,
      email: this.state.email,
    }).catch((err) => console.log(err));
  };

  componentDidMount(prevProps) {
    const token = localStorage.getItem('token');
    const decoded = jwt_decode(token);
    const agentid = decoded._id;
    Axios.get(`/agentdetail/${agentid}`)
      .then(({ data }) => {
        this.setState({ username: data.username });
      })
      .catch((err) => console.log(err));
  }

  render() {
    //const { username, email } = this.state;
    return (
      <div className="settingsIt">
        <div className="row" id="profilephoto">
          {/* <div className="col-5">
            <img src={imageresponse} className="img-fluid" alt="Responsive image" />
          </div> */}

          <div className="col-6">
            <form onSubmit={this.editinfo} className="modal-body">
              <div className="row">
                <i className="fa fa-user col-2 mt-2" aria-hidden="true"></i>

                <input
                  type="text"
                  className="form-control border-0 rounded-1-right mb-2 align-middle pl-3 bg-white"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  placeholder="Username"
                  required
                  name="username"
                  onChange={this.handleChange}
                  value={this.state.username}
                ></input>
              </div>
              {/* <div className="row">
                    <i className="fa fa-globe col-2 pt-1"></i>
                    <h6>www.johnsite.com</h6>
                  </div> */}
            </form>
          </div>
        </div>

        <Link to="/resetpassword" className="row" id="settingsBackground">
          <div className="col-3">
            <i className="fa fa-calculator fa-3x colorIt" id="backgroundIt"></i>
          </div>
          <div className="col-6">
            <h4 className="paddingfornames">Change Password</h4>
          </div>
          <div className="col-3">
            <i
              className="fa fa-arrow-circle-right fa-3x"
              aria-hidden="true"
            ></i>
          </div>
        </Link>

        <button
          className="row"
          id="settingsBackground"
          onClick={() => {
            localStorage.removeItem('token');
            window.open('/signin', '_self');
          }}
        >
          <div className="col-12">
            <h3 className="paddingfornames" style={{ color: '#0000FF' }}>
              LOGOUT
            </h3>
          </div>
        </button>
      </div>
    );
  }
}

export default settings;
