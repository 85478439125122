import React from 'react';
import DateConverter from '../../../utils/DateConverter';
import './ReceivedMessage.css';

const ReceivedMessage = (props) => {
  const { message } = props;

  return (
    <div
      style={{ display: 'flex', width: '100%', justifyContent: 'flex-start' }}
    >
      <div className="received-message">
        {message.message.messageType === 'Text' ? (
          <div style={{ wordWrap: 'break-word' }}>
            {message.message.messageBody}
          </div>
        ) : null}
        {message.message.messageType === 'File' ? (
          <img
            alt="receivedImage"
            src={message.message.messageBody}
            width={150}
            height={150}
            onClick={() => {
              props.imageToPreview(message.message.messageBody);
            }}
            style={{ cursor: 'pointer' }}
          />
        ) : null}
        <div className="received-message-username-and-date">
          {message.fromUser.userName}
          {message.creationDate && (
            <p>{DateConverter.toLongString(message.creationDate)}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReceivedMessage;
