/** @format */

import produce from 'immer';
import { ProductsActionTypes } from './types';

const initialState = {
  productIds: [],
  currentPage: 0,
  totalPage: 0,
  rowsPerPage: 0,
  prodStatus: '',
};

const setProductIds = (state, action) =>
  produce(state, (draft) => {
    const { products, currentPage, totalPage, rowsPerPage, prodStatus } =
      action.payload;

    const ids = products.map((p) => {
      if (p._id) return p._id.toString();
    });

    draft.productIds = ids;
    draft.currentPage = currentPage;
    draft.totalPage = totalPage;
    draft.rowsPerPage = rowsPerPage;
    draft.prodStatus = prodStatus;
  });

const updateProductIds = (state, action) =>
  produce(state, (draft) => {
    const { products, currentPage, totalPage, rowsPerPage, prodStatus } =
      action.payload;

    const set = new Set(draft.productIds);

    products.forEach((p) => {
      set.add(p._id.toString());
    });

    draft.productIds = [...set];
    draft.currentPage = currentPage;
    draft.totalPage = totalPage;
    draft.rowsPerPage = rowsPerPage;
    draft.prodStatus = prodStatus;
  });

export const ProductsReducer = (
  state = initialState,
  action = { type: 'UNKNOWN' }
) => {
  switch (action.type) {
    case ProductsActionTypes.SET_PRODUCT_IDS:
      return setProductIds(state, action);
    case ProductsActionTypes.UPDATE_PRODUCT_IDS:
      return updateProductIds(state, action);

    default:
      return state;
  }
};
