import React, { Component } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import './Template.css';
import { Link } from 'react-router-dom';
import Axios from '../../services/Axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Confirmer, SuccessToaster } from 'src/services/SweetAlerts';

const $ = window.$;
class Templates extends Component {
  constructor() {
    super();
    this.state = {
      templates: [],
      templateName: '',
      options: {},
      templateId: '',
    };
  }

  componentDidMount = () => {
    Axios.get('/template/getTemplate')
      .then(({ data }) => this.setState({ templates: data.templates }))
      .catch((err) => console.log(err));
    $('#addTemplateModal').on('hidden.bs.modal', (e) => {
      this.setState({ templateName: '' });
    });
    Axios.get('/publictemplate/getPublicTemplate')
      .then(({ data }) => {
        this.setState({ options: data });
        console.log(data);
      })
      .catch((err) => console.log(err));
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleTemplate = (e) => {
    console.log(e);
    this.setState({ templateId: e });
    Axios.get('/publictemplate/getPublicTemplate/' + e).then((res) => {
      console.log(res.data.templates.data);
    });
  };

  createTemplate = (data) => {
    const { templateName } = this.state;
    Axios.post('/template/addTemplate', data)
      .then(({ data }) => {
        console.log(data, 'teempalated');
        const idx = data.templates.findIndex((a) => a.name === templateName);
        SuccessToaster.fire({ text: data.msg });
        // window.open("/template/" + data.templates[idx]._id, "_self");
        window.location.reload();
      })
      .catch((err) => {
        window.location.reload();
      });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { templates, templateName, templateId } = this.state;
    // const data=new FormData();
    // data.append("name", templateName);
    var data = {};
    if (templateName == '')
      return Confirmer.fire({ text: 'Please enter Template name' });
    if (!templateName.match(/^\w+( +\w+)*$/))
      return Confirmer.fire({ text: 'Template name must be alphanumeric' });
    const idx =
      templates && templates.findIndex((a) => a.name === templateName);
    if (idx > -1)
      return Confirmer.fire({ text: 'Template with same name already exists' });
    data['name'] = templateName;
    if (templateId != '') {
      Axios.get('/publictemplate/getPublicTemplate/' + templateId).then(
        (res) => {
          data = res.data.templates.data;
          data['name'] = templateName;
          this.createTemplate(data);
        }
      );
    } else {
      data['name'] = templateName;
      this.createTemplate(data);
    }
  };

  render = () => {
    const { templateName, templates, options } = this.state;
    return (
      <div>
        <div className="container py-2">
          {/* modal */}
          <div
            className="modal fade  bd-example-modal-sm"
            id="addTemplateModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="addTemplateModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm  modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title" id="addTemplateModalLabel">
                    Add Template
                  </h6>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <form onSubmit={this.handleSubmit} className="modal-body">
                  <div className="form-group">
                    Make a copy of public template
                    <Autocomplete
                      ListboxProps={{
                        style: { maxHeight: 200, overflow: 'auto' },
                      }}
                      options={options}
                      getOptionLabel={(option) => option.name}
                      defaultValue={options}
                      onChange={(event, newValue) => {
                        if (newValue != null) {
                          this.handleTemplate(newValue.id);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose Public Template"
                          margin="normal"
                          variant="outlined"
                        />
                      )}
                    />
                    or just create an empty one
                    <input
                      type="text"
                      name="templateName"
                      value={templateName}
                      onChange={this.handleChange}
                      className="form-control"
                      placeholder="Enter template name  "
                    />
                  </div>
                  <button type="submit" className="btn btn-primary btn-block">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6 col-lg-2">
              <div
                className="card template-card bg-light"
                onClick={() => $('#addTemplateModal').modal('show')}
              >
                <div className="card-body d-flex align-items-center justify-content-center c-pointer text-center py-4">
                  <i className="fas fa-plus  fa-4x"></i>
                </div>
              </div>
            </div>
            {templates &&
              templates.map((template) => {
                return (
                  <div className="col-12 col-md-6 col-lg-2">
                    <Link
                      to={`/template/${template._id}`}
                      className="card template-card bg-light"
                    >
                      <div className="card-body d-flex align-items-center c-pointer text-center py-4 ">
                        <h5 className="card-text text-center p-3">
                          {template.name}
                        </h5>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  };
}
export default Templates;
