/** @format */

import React, { useEffect } from 'react';
import {
  usePaginationActions,
  usePaginationData,
} from '../../redux/PaginiationStore';

function Pagination({
  curPage,
  totalPage,
  next,
  previous,
  rowPerPage,
  onRowNumberChange,
}) {
  const paginatioData = usePaginationData();
  const { doSetPageSize } = usePaginationActions();

  const handlePageSizeChange = (e) => {
    doSetPageSize(e.target.value);
  };

  useEffect(() => {
    onRowNumberChange(paginatioData.pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatioData.pageSize]);

  return (
    <div className="shadow d-flex justify-content-between py-3 px-5">
      <div className="d-flex align-items-center">
        Rows per page:
        <select
          value={paginatioData.pageSize}
          style={{ width: 70 }}
          className="form-control ml-2"
          onChange={handlePageSizeChange}
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
      <div>
        {curPage}-{totalPage}
        <button
          className="btn btn-link"
          onClick={() => previous(curPage === 0 ? 0 : curPage - 1)}
        >
          <i className="fas fa-chevron-left"></i>
        </button>
        <button
          className="btn btn-link"
          onClick={() => next(curPage === totalPage ? curPage : curPage + 1)}
        >
          <i className="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
}

export default Pagination;
